import { useContext, useEffect } from "react";

import { WorkspaceContext } from "../context/Workspace/WorkspaceContext";
import { fetchWorkspaceById } from "../connectors/api";

let hasFetched = false;

const useWorkspaceContext = (workspaceId) => {
  const { workspace, setWorkspace } = useContext(WorkspaceContext);

  useEffect(() => {
    const fetchWorkspaceData = async () => {
      try {
        const response = await fetchWorkspaceById(workspaceId);
        setWorkspace(response);
      } catch (error) {
        console.error("Failed to fetch workspace data", error);
      }
    };

    if (!workspace?.id && workspaceId && !hasFetched) {
      fetchWorkspaceData();
      hasFetched = true;
    }
  }, [workspace?.id, workspaceId, setWorkspace]);

  return { workspace, setWorkspace };
};

export default useWorkspaceContext;
