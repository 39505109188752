import React, { useState, useEffect } from 'react';
import GenericFormField from '../../components/GenericFormField/GenericFormField';
import Subform from '../../components/Subform/Subform';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const StyledNewProjectForm = styled.form`
  padding: 40px;
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  max-width: 800px;
  width: 100%;
  flex-direction: column;
  gap: 20px;
`;

const StyledButton = styled.button`
  padding: 12px 20px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #357ab8;
  }
`;

const CancelButton = styled(StyledButton)`
  background-color: #ccc;
  &:hover {
    background-color: #999;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const NewProjectForm = ({ configurations }) => {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  // Function to initialize formData based on configurations
  const initializeFormData = (configurations) => {
    const initialData = {};
    configurations.forEach(section => {
      section.elements.forEach(element => {
        if (element.type === 'select') {
          initialData[element.value] = {
            option: element.default || '',
            details: element.options?.find(opt => opt.value === element.default)?.details?.reduce((acc, detail) => ({
              ...acc,
              [detail.value]: detail.default || ''
            }), {}) || {}
          };
        } else {
          initialData[element.value] = element.default || '';
        }
      });
    });
    return initialData;
  };

  console.log("FORM: ", formData);

  const handleSubmit = async (event) => {
  
    const payload = {
      name: formData.projectName,
      description: formData  // Assuming projectConfig contains the configuration description
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_HOST}/api/api/projects`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(payload)
      });
  
      if (response.ok) {
        const data = await response.json();
        navigate('/projects');  // Redirect to projects page or handle accordingly
      } else {
        console.error('Failed to create project');
      }
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };


  const handleCancel = () => {
    navigate('/projects');  // Navigate to the home page or any other page
  };
  // Effect hook to set initial form data when configurations are loaded
  useEffect(() => {
    if (configurations) {
      setFormData(initializeFormData(configurations));
    }
  }, [configurations]);


  const handleChange = (id, value, category, elementType, details = []) => {
    console.log(id, value, category, elementType, details);
    console.log("Details", details);
    setFormData(prev => {
      const categoryParts = category.split('.');
      const lastPart = categoryParts.pop();

      console.log("CATEGORY PARTS", categoryParts);
      console.log("LAST PART", lastPart);

      let nestedData = prev;
      categoryParts.forEach(part => {
        nestedData = nestedData[part] = nestedData[part] || {};
      });

      switch (elementType) {
        case 'text':
          nestedData[lastPart] = value;
          break;
        case 'select':
          if (details.length > 0) {
            nestedData[lastPart] = {
              option: value,
              details: details.reduce((acc, detail) => {
                acc[detail.value] = detail.default || "";
                return acc;
              }, {})
            };
          } else {
            console.log("NO DETAILS");
            nestedData[lastPart] = value;
          }
          break;
        default:
          break;
      }
      return { ...prev };
    });
  };

  const findDetailsForSelectedOption = (options, selectedValue) => {
    console.log("HERE");
    if (!options) return [];
    console.log("THERE");
    const selectedOption = options.find(option => option.value === selectedValue);
    console.log("SELECTED OPTION", selectedOption);
    return selectedOption ? selectedOption.details : [];
  };

  return (
    <StyledNewProjectForm>
      {configurations.map(section => (
        <div key={section.id}>
          <h2>{section.name}</h2>
          {section.elements.map(element => (
            <React.Fragment key={element.id}>
              <GenericFormField
                fieldConfig={element}
                value={formData[element.value] || formData[element.value]?.option|| element.default || ''}
                onChange={(e) => {
                  const newDetails = element.type === 'select' ? findDetailsForSelectedOption(element.options, e.target.value) : [];
                  handleChange(element.id, e.target.value, element.value, element.type, newDetails);
                }}
              />
              {formData[element.value]?.details && (
                <Subform key={`subform-${element.id}`}>
                  {element.options?.find(option => option.value === formData[element.value].option)?.details?.map(detail => {
                    const detailValue = formData[element.value]?.details[detail.value] || detail.default || '';
                    console.log(`Detail ID: ${detail.id}, Value: ${detailValue}`); // Debugging to log the value
                    return (
                      <GenericFormField
                        id={detail.id}
                        fieldConfig={detail}
                        value={detailValue}
                        onChange={(e) => handleChange(detail.id, e.target.value, `${element.value}.details.${detail.value}`, element.type )}
                      />
                    );
                  })}
                </Subform>
              )}
            </React.Fragment>
          ))}
        </div>
      ))}
      <ButtonContainer>
        <CancelButton type="button" onClick={handleCancel}>Cancel</CancelButton>
        <StyledButton type="button" onClick={() => handleSubmit(formData)}>Create Project</StyledButton>
      </ButtonContainer>
    </StyledNewProjectForm>
  );
};

export default NewProjectForm;