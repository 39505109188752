const DEFAULT_HOST = "http://localhost";
const HOST = process.env.REACT_APP_HOST ?? DEFAULT_HOST;

// API endpoints
const CHECK_TOKEN = "/api/auth/check-token";
const DASHBOARD_DATA = "/api/api/projects/:id/dashboard";
const LOGIN = "/api/auth/login";
const PREDICTION_AVAILABLE_CHECK = "/api/api/projects/:id/check-models";
const LOGIN_LABEL_STUDIO = "/api/auth/login-label-studio";
const PROJECTS_LIST = "/api/api/projects";
const PROJECT_INFO_BY_ID = "/api/api/projects/:id";
const PROJECT_MODEL = "/api/api/projects/:id/models";
const TRIGGER_EVAL_MODEL = "/api/api/projects/:id/eval";
const TRIGGER_GET_PREDICTION = "/api/api/projects/:id/get-predictions";
const TRIGGER_PROJECT_DATA_UPLOAD = "/api/api/projects/:id/upload";
const TRIGGER_TRAIN_MODEL = "/api/api/projects/:id/train";

// External endpoints
const LABEL_STUDIO_PROJECT = "/label-studio/projects/:id";

export const CHECK_TOKEN_FETCH_URL = HOST + CHECK_TOKEN;
export const DASHBOARD_DATA_FETCH_URL = HOST + DASHBOARD_DATA;
export const LOGIN_FETCH_URL = HOST + LOGIN;
export const LOGIN_LABEL_STUDIO_FETCH_URL = HOST + LOGIN_LABEL_STUDIO;
export const PROJECTS_LIST_FETCH_URL = HOST + PROJECTS_LIST;
export const PROJECT_INFO_BY_ID_FETCH_URL = HOST + PROJECT_INFO_BY_ID;
export const TRIGGER_EVAL_MODEL_FETCH_URL = HOST + TRIGGER_EVAL_MODEL;
export const TRIGGER_GET_PREDICTION_FETCH_URL = HOST + TRIGGER_GET_PREDICTION;
export const TRIGGER_TRAIN_MODEL_FETCH_URL = HOST + TRIGGER_TRAIN_MODEL;
export const TRIGGER_PROJECT_DATA_UPLOAD_FETCH_URL =
  HOST + TRIGGER_PROJECT_DATA_UPLOAD;
export const PREDICTION_AVAILABLE_CHECK_FETCH_URL =
  HOST + PREDICTION_AVAILABLE_CHECK;
export const PROJECT_MODEL_FETCH_URL = HOST + PROJECT_MODEL;

export const LABEL_STUDIO_PROJECT_URL = HOST + LABEL_STUDIO_PROJECT;
