// TODO: check and refactor this file

import React from "react";
import styled from "styled-components";
import { FaTools } from "react-icons/fa";

import Layout from "../../components/Layout/Layout";

const UnderConstructionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 200px); // Account for header/footer
  padding: 40px;
  text-align: center;
`;

const ContentContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 48px;
  color: #333;
  margin-bottom: 24px;
`;

const Message = styled.p`
  font-size: 24px;
  color: #666;
  margin-bottom: 32px;
`;

const IconWrapper = styled.div`
  font-size: 120px;
  color: #182eae;
  margin-bottom: 32px;
  animation: wrench 6s ease infinite;

  @keyframes wrench {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(15deg);
    }
    20% {
      transform: rotate(-15deg);
    }
    30% {
      transform: rotate(15deg);
    }
    40% {
      transform: rotate(-15deg);
    }
    50% {
      transform: rotate(15deg);
    }
    55% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const UnderConstructionPage = () => (
  <Layout title="" isLoading={false} headerChildren={null}>
    <UnderConstructionWrapper>
      <ContentContainer>
        <IconWrapper>
          <FaTools />
        </IconWrapper>
        <Title>Under Construction</Title>
        <Message>We're working hard to bring you something amazing. Stay tuned for updates!</Message>
      </ContentContainer>
    </UnderConstructionWrapper>
  </Layout>
);

export default UnderConstructionPage;
