import React, { useEffect, useState, useRef } from "react";
import { Button, notification, Tooltip } from "antd";

import {
  fetchProjectDataUpload,
  fetchTriggerGetPrediction,
  fetchPredictionAvailableCheck,
} from "../../connectors/api";

const DataPageTriggerButtons = ({ projectId, iframeRef }) => {
  const [api, contextHolder] = notification.useNotification();

  const [predictionButtonMessage, serPredictionButtonMessage] = useState(null);
  const [isPredictionAvailable, setIsPredictionAvailable] = useState(false);

  const [isUploadDataDisabled, setIsUploadDataDisabled] = useState(false);

  useEffect(() => {
    const fetchPredictionAvailableData = async () => {
      try {
        const response = await fetchPredictionAvailableCheck(projectId);
        setIsPredictionAvailable(response.isPredictionAvailable);
        serPredictionButtonMessage(response.message);
      } catch (error) {
        console.log("Error while checking if prediction is available: ", error);
      }
    };

    fetchPredictionAvailableData();
  }, [projectId]);

  const handleUploadData = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.onchange = async ({ target }) => {
      setIsUploadDataDisabled(true);
      try {
        const response = await fetchProjectDataUpload(projectId, target.files);
        api.success({ message: response.message });
        setIsUploadDataDisabled(false);

        setTimeout(() => {
          if (iframeRef && iframeRef.current) { // Added check for iframeRef and iframeRef.current
            const iframeDocument =
              iframeRef.current.contentDocument ||
              iframeRef.current.contentWindow.document;

            const refreshButton = iframeDocument.querySelector(
              "div.dm-tab-panel > div.dm-space.dm-space_direction_horizontal.dm-space_size_small > button.dm-button_look_primary"
            );
            if (refreshButton) {
              refreshButton.click();
            }
          }
        }, 1000);
      } catch (error) {
        console.error("Error uploading data:", error);
        api.error({ message: error.message });
        setIsUploadDataDisabled(false);
      }
    };
    input.click();
  };

  const handleGetPredictions = async () => {
    setIsPredictionAvailable(false);
    try {
      const response = await fetchTriggerGetPrediction(projectId);
      console.log("Predictions received:", response);
      api.success({ message: response.message });
      serPredictionButtonMessage(
        "Prediction is in progress, wait for it to end before starting another one"
      );
    } catch (error) {
      console.error("Error getting predictions:", error);
      api.error({ message: error.message });
      setIsPredictionAvailable(true);
    }
  };

  return (
    <>
      {contextHolder}
      <Button
        disabled={isUploadDataDisabled}
        onClick={handleUploadData}
        type="primary"
        size="large"
      >
        Upload Data
      </Button>
      <Tooltip title={predictionButtonMessage}>
        <Button
          disabled={!isPredictionAvailable}
          onClick={handleGetPredictions}
          type="primary"
          size="large"
        >
          Get Predictions
        </Button>
      </Tooltip>
    </>
  );
};

export default DataPageTriggerButtons;
