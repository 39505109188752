// TODO: check and refactor this file
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { FaPlus, FaPaperPlane } from "react-icons/fa";

import Button from "../../components/Button/Button";
import Layout from "../../components/Layout/Layout";
import { fetchPipelineById, fetchRagflowApiToken } from "../../connectors/api";
import { RAGFLOW_HOST_URL } from "../../constants/endpoints";
import { ROUTES } from "../../constants/routes";
import { getUrl } from "../../helpers/navigation";
import useWorkspaceContext from "../../hooks/useWorkspaceContext";

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 50vw);
  margin: 24px auto;
  height: calc(100vh - 20vh);
  border-radius: 20px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
`;

const MessageList = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 24px;
  background-color: #f7f9fc;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d1d9e6;
    border-radius: 3px;
  }
`;

const Message = styled.div`
  margin-bottom: 16px;
  padding: 14px 18px;
  border-radius: 16px;
  max-width: 75%;
  font-size: 15px;
  line-height: 1.6;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  ${(props) =>
    props.$isUser
      ? `
    background: linear-gradient(135deg, #182eae, #266BD3);
    color: white;
    margin-left: auto;
  `
      : `
    background: #ffffff;
    color: #334d6e;
    margin-right: auto;
    border: 1px solid #e0e5f2;
  `}

  p {
    margin: 0;
  }

  pre {
    background: ${(props) => (props.$isUser ? "rgba(255, 255, 255, 0.1)" : "#f6f8fa")};
    padding: 12px;
    border-radius: 8px;
    overflow-x: auto;
  }

  code {
    font-family: monospace;
    background: ${(props) => (props.$isUser ? "rgba(255, 255, 255, 0.1)" : "#f6f8fa")};
    padding: 2px 4px;
    border-radius: 4px;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 1.3em;
    font-weight: 600;
  }

  h4 {
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: 1.1em;
    font-weight: 600;
  }

  ul {
    margin: 12px 0;
    padding-left: 24px;
  }

  li {
    margin: 6px 0;
  }

  strong {
    font-weight: 600;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  background-color: #ffffff;
  border-top: 1px solid #e0e5f2;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 14px;
  border: 1px solid #d1d9e6;
  border-radius: 12px;
  font-size: 15px;
  margin-right: 12px;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;

  &:focus {
    outline: none;
    border-color: #182eae;
    box-shadow: 0 0 0 3px rgba(38, 107, 211, 0.3);
  }

  &::placeholder {
    color: #a3aed0;
  }
`;

const AgentTestingPage = () => {
  const [apiKey, setApiKey] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [messages, setMessages] = useState([]);
  const [pipeline, setPipeline] = useState(null);
  const [ragflowAgentId, setRagflowAgentId] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const { workspaceId, pipelineId } = useParams();

  const { workspace } = useWorkspaceContext(workspaceId);

  const initiateSession = async () => {
    try {
      const { api_key } = await fetchRagflowApiToken();
      setApiKey(api_key);
      console.log(api_key);
      const pipelineData = await fetchPipelineById(workspaceId, pipelineId);
      if (!pipelineData?.ragflow_agent_id) {
        throw new Error("No agent ID found for agent");
      }
      setPipeline(pipelineData);
      setRagflowAgentId(pipelineData.ragflow_agent_id);

      const response = await fetch(`${RAGFLOW_HOST_URL}/api/v1/agents/${pipelineData.ragflow_agent_id}/sessions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${api_key}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to initiate session");
      }

      const data = await response.json();
      console.log(data);
      setSessionId(data.data.id);
      setMessages(data.data.message.map((msg) => ({ text: msg.content, isUser: false })));
    } catch (error) {
      console.error("Error initiating session:", error);
    }
  };

  useEffect(() => {
    initiateSession();
  }, [workspaceId, pipelineId]);

  const handleNewTest = async () => {
    setMessages([]);
    setSessionId(null);
    await initiateSession();
  };

  const handleSend = async () => {
    if (!inputValue.trim() || !sessionId || !ragflowAgentId || !apiKey) {
      return;
    }

    const newMessage = { text: inputValue, isUser: true };
    setMessages((prev) => [...prev, newMessage]);
    setInputValue("");

    try {
      const response = await fetch(`${RAGFLOW_HOST_URL}/api/v1/agents/${ragflowAgentId}/completions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          question: inputValue,
          stream: true,
          session_id: sessionId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send message");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = "";

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });
        let boundary = buffer.indexOf("\n");
        while (boundary !== -1) {
          const line = buffer.slice(0, boundary).trim();
          buffer = buffer.slice(boundary + 1);

          if (line.startsWith("data:")) {
            try {
              const parsed = JSON.parse(line.slice(5).trim());
              if (parsed.data?.answer) {
                setMessages((prevMessages) => {
                  const newMessages = [...prevMessages];
                  const lastMessage = newMessages[newMessages.length - 1];

                  if (!lastMessage.isUser) {
                    return [...newMessages.slice(0, -1), { ...lastMessage, text: parsed.data.answer }];
                  }
                  return [...newMessages, { text: parsed.data.answer, isUser: false }];
                });
              }
            } catch (e) {
              console.error("Error parsing message:", e);
            }
          }
          boundary = buffer.indexOf("\n");
        }
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prev) => [
        ...prev,
        {
          text: "Failed to send message. Please try again.",
          isUser: false,
        },
      ]);
    }
  };

  return (
    <Layout
      title="Agent Testing"
      breadcrumbItems={[
        { label: "Workspaces", path: ROUTES.WORKSPACES },
        { label: workspace?.name, path: getUrl(ROUTES.WORKSPACE_INFO, { workspaceId }) },
        { label: "All Agents", path: getUrl(ROUTES.PIPELINES, { workspaceId }) },
        { label: pipeline?.name, path: getUrl(ROUTES.PIPELINE_INFO, { workspaceId, pipelineId }) },
        { label: "Testing", path: getUrl(ROUTES.AGENT_TESTING, { workspaceId, pipelineId }) },
      ]}
      headerChildren={
        <Button size="large" type="primary" onClick={handleNewTest}>
          <FaPlus />
          New Test
        </Button>
      }
    >
      <ChatContainer>
        <MessageList>
          {messages.map((message, index) => (
            <Message key={index} $isUser={message.isUser}>
              <ReactMarkdown>{message.text}</ReactMarkdown>
            </Message>
          ))}
        </MessageList>
        <InputContainer>
          <Input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Type your message..."
            onKeyPress={(e) => e.key === "Enter" && handleSend()}
          />
          <Button size="large" type="primary" onClick={handleSend}>
            <FaPaperPlane />
            Send
          </Button>
        </InputContainer>
      </ChatContainer>
    </Layout>
  );
};

export default AgentTestingPage;
