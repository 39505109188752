import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button as AntdButton, Form, Input, Popconfirm, notification } from "antd";
import { FaTrashAlt } from "react-icons/fa";

import Button from "../../components/Button/Button";
import Layout from "../../components/Layout/Layout";
import { fetchWorkspaceById, fetchUpdateWorkspace, fetchDeleteWorkspace } from "../../connectors/api";
import { getUrl } from "../../helpers/navigation";
import { ROUTES } from "../../constants/routes";

const SubmitButton = ({ form, children, originalData }) => {
  const [submittable, setSubmittable] = React.useState(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(values.name !== originalData.name || values.description !== originalData.description))
      .catch(() => setSubmittable(false));
  }, [form, values, originalData]);

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable} className="submit-button">
      {children}
    </Button>
  );
};

const WorkspaceEditPageStyledWrapper = styled.div`
  max-width: 600px;

  .edit-workspace-action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;

    .submit-button {
      margin-right: 10px;
    }
  }
`;

const WorkspaceEditPage = () => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(true);
  const [workspaceInfo, setWorkspaceInfo] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { name, description } = await fetchWorkspaceById(workspaceId);
        setWorkspaceInfo({ name, description });
        form.setFieldsValue({ name, description });
        setIsLoading(false);
      } catch (error) {
        notification.error({
          message: "Failed to load workspace information!",
          description: "Please reload the page, or if the problem persists, contact the Somia team for assistance.",
        });
        console.error("Failed to fetch workspace data", error);
      }
    };

    fetchData();
  }, [workspaceId, form]);

  const handleUpdate = async (values) => {
    try {
      await fetchUpdateWorkspace(workspaceId, values);
      notification.success({ message: "Workspace updated successfully" });
      navigate(getUrl(ROUTES.WORKSPACE_INFO, { workspaceId }));
    } catch (error) {
      notification.error({
        message: "Error updating workspace",
        description: "Please try again later, or if the problem persists, contact the Somia team for assistance.",
      });
      console.error("Failed to update workspace data", error);
    }
  };

  const handleDelete = async () => {
    try {
      await fetchDeleteWorkspace(workspaceId);
      notification.success({ message: "Workspace deleted successfully" });
      navigate(getUrl(ROUTES.WORKSPACES));
    } catch (error) {
      notification.error({
        message: "Error deleting workspace",
        description: "Please try again later, or if the problem persists, contact the Somia team for assistance.",
      });
      console.error("Failed to delete workspace", error);
    }
  };

  const handleCancel = () => {
    navigate(getUrl(ROUTES.WORKSPACE_INFO, { workspaceId }));
  };

  return (
    <Layout 
      title="Edit Workspace" 
      breadcrumbItems={[
        { label: "Workspaces", path: ROUTES.WORKSPACES },
        { label: workspaceInfo?.name, path: getUrl(ROUTES.WORKSPACE_INFO, { workspaceId }) },
        { label: "Edit Workspace", path: getUrl(ROUTES.WORKSPACE_EDIT, { workspaceId }) }
      ]}
      isLoading={isLoading}
    >
      <WorkspaceEditPageStyledWrapper>
        <Form form={form} onFinish={handleUpdate} layout="vertical">
          <Form.Item
            name="name"
            label="Workspace Name"
            rules={[{ required: true, message: "Please input workspace name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Please input workspace description!" }]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
          <div className="edit-workspace-action-buttons">
            <div className="safe-buttons">
              <Form.Item>
                <SubmitButton form={form} originalData={workspaceInfo}>
                  Save changes
                </SubmitButton>
                <Button type="secondary" onClick={handleCancel}>Cancel</Button>
              </Form.Item>
            </div>
            <div className="danger-button">
              <Form.Item>
                <Popconfirm
                  title="Delete the workspace"
                  description="Are you sure you want to delete this workspace?"
                  okButtonProps={{ danger: true }}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={handleDelete}
                >
                  <AntdButton type="primary" danger>
                    <FaTrashAlt /> Delete workspace
                  </AntdButton>
                </Popconfirm>
              </Form.Item>
            </div>
          </div>
        </Form>
      </WorkspaceEditPageStyledWrapper>
    </Layout>
  );
};

export default WorkspaceEditPage;
