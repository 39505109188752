import styled from "styled-components";
import { Button as AntButton } from "antd";

const StyledButton = styled(AntButton)`
  padding: ${({ size }) => (size === "large" ? "12px 24px" : "8px 16px")};
  font-size: ${({ size }) => (size === "large" ? "16px" : "14px")};
  border-radius: 8px;
  transition: all 0.3s;
  background-color: ${({ type }) => (type === "primary" ? "#182EAE" : "white")};
  color: ${({ type }) => (type === "primary" ? "white" : "black")};
  border: ${({ type }) => (type === "primary" ? "none" : "1px solid #8c8c8c")};

  &:hover {
    background-color: ${({ type }) => (type === "primary" ? "#266BD3" : "white")};
    color: ${({ type }) => (type === "primary" ? "white" : "#266BD3")};
    border-color: ${({ type }) => (type === "primary" ? "none" : "#266BD3")};
  }

  &:disabled {
    background-color: #e8e8e8;
    cursor: not-allowed;
    transform: none;
    border: none;
    &:hover {
      background-color: #e8e8e8;
      transform: none;
      color: rgba(0, 0, 0, 0.25);
      border: none;
    }
  }
`;

export default StyledButton;
