import React, { useState, useEffect } from 'react';
import { notification, Card, Typography, Space, Divider } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { fetchUserProfile, updateUserProfile } from '../../connectors/api';
import Layout from '../../components/Layout/Layout';
import FormInput from '../../components/FormInput/FormInput';
import Button from '../../components/Button/Button';
import styled from 'styled-components';
import { ROUTES } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const StyledCard = styled(Card)`
  max-width: 800px;
  margin: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background: #ffffff;
`;

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const PageHeader = styled.div`
  
  h1 {
    font-size: 32px;
    font-weight: 900;
    color: #182eae;
    margin-bottom: 8px;
  }
  
  p {
    color: #666;
    font-size: 16px;
    margin-top: 0px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 10px;
  padding-top: 10px;
`;

const UserConfiguration = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    username: ''
  });
  const [initialData, setInitialData] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        setIsLoading(true);
        const data = await fetchUserProfile();
        setUserData({
          username: data.username
        });
        setInitialData({
          username: data.username
        });
      } catch (error) {
        notification.error({ 
          message: 'Failed to load user data',
          description: error.message
        });
      } finally {
        setIsLoading(false);
      }
    };
    loadUserData();
  }, []);

  useEffect(() => {
    const changed = JSON.stringify(userData) !== JSON.stringify(initialData);
    setHasChanges(changed);
  }, [userData, initialData]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setUserData(prevData => ({
      ...prevData,
      username: value
    }));
  };

  const handleSubmit = async () => {
    if (!userData.username.trim()) {
      notification.error({
        message: 'Validation Error',
        description: 'Username cannot be empty'
      });
      return;
    }

    try {
      setIsLoading(true);
      await updateUserProfile(userData);
      setInitialData(userData);
      notification.success({ 
        message: 'Success',
        description: 'Profile updated successfully'
      });
    } catch (error) {
      notification.error({ 
        message: 'Update failed',
        description: error.message
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <StyledCard>
        <StyledForm>
          <PageHeader>
            <Title level={1}>User Configuration</Title>
            <p>Manage your account settings and preferences</p>
          </PageHeader>
          
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <FormInput
              label="Username"
              type="text"
              name="username"
              value={userData.username}
              onChange={handleInputChange}
              id="username"
              required
              prefix={<UserOutlined />}
              placeholder="Enter your username"
            />

            <ButtonContainer>
              <Button
                size="medium"
                onClick={() => navigate(ROUTES.WORKSPACES)}
              >
                Cancel
              </Button>
              <Button 
                type="primary"
                size="medium"
                onClick={handleSubmit}
                disabled={!hasChanges || isLoading}
                loading={isLoading}
              >
                Save Changes
              </Button>
            </ButtonContainer>
          </Space>
        </StyledForm>
      </StyledCard>
    </Layout>
  );
};

export default UserConfiguration;