import styled from "styled-components";

const SidebarStyledWrapper = styled.div`
  width: ${({ $isCollapsed }) => ($isCollapsed ? "40px" : "200px")};
  min-width: ${({ $isCollapsed }) => ($isCollapsed ? "40px" : "200px")};
  background-color: #ffffff;
  padding: ${({ $isCollapsed }) => ($isCollapsed ? "10px" : "20px")};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .sidebar-header {
    display: flex;
    flex-direction: column;
    align-items: left;

    .logo {
      width: 80px;
      margin-bottom: 10px;
    }

    .sidebar-header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      position: relative;
      margin-bottom: 20px;
    }

    .sidebar-logo {
      padding-top: 30px;
    }

    .sidebar-company-name,
    .sidebar-platform-name {
      display: ${({ $isCollapsed }) => ($isCollapsed ? "none" : "block")};
      font-size: 30px;
      font-weight: 900;
      color: #182eae;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
      letter-spacing: -0.5px;
      margin-left: 10px;
      cursor: pointer;
    }

    .sidebar-platform-name {
      font-weight: 400;
    }
  }

  nav.sidebar-navigation {
    width: 100%;
    margin-top: 20px;

    ul.sidebar-navigation-list {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li.sidebar-navigation-item {
        &.submenu-item {
          // Additional styling if needed for submenu items
        }
      }
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
  }
`;

export default SidebarStyledWrapper;
