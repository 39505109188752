import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { notification } from "antd";
import { FaPlus } from "react-icons/fa";

import Button from "../../components/Button/Button";
import Layout from "../../components/Layout/Layout";
import PipelineCard from "../../components/PipelineCard/PipelineCard";
import { fetchPipelines } from "../../connectors/api";
import { ROUTES } from "../../constants/routes";
import { getUrl } from "../../helpers/navigation";
import useWorkspaceContext from "../../hooks/useWorkspaceContext";

const PipelinesGrid = styled.div`
  display: flex;
  gap: 20px;
  justify-content: left;
  flex-wrap: wrap;
`;

const CreateFirstPipelineContainer = styled.div`
  background: #f8f8f8;
  border-radius: 12px;
  border: 1px dashed #e8e8e8;
  color: #8c8c8c;
  cursor: pointer;
  font-size: 18px;
  margin: 32px 0;
  padding: 24px;
  text-align: center;
`;

const PipelinesPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [pipelines, setPipelines] = useState([]);

  const { workspaceId } = useParams();
  const navigate = useNavigate();

  const { workspace } = useWorkspaceContext(workspaceId);

  useEffect(() => {
    const loadPipelines = async () => {
      try {
        const data = await fetchPipelines(workspaceId);
        setPipelines(data);
      } catch (error) {
        notification.error({
          message: "Failed to load agents!",
          description: "Please reload the page, or if the problem persists, contact the Somia team for assistance.",
        });
        console.error("Error loading pipelines:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadPipelines();
  }, [workspaceId]);

  const handleNewPipeline = () => {
    navigate(getUrl(ROUTES.PIPELINE_NEW, { workspaceId }));
  };

  return (
    <Layout
      title="All Agents"
      isLoading={isLoading}
      breadcrumbItems={[
        { label: "Workspaces", path: ROUTES.WORKSPACES },
        { label: workspace?.name, path: getUrl(ROUTES.WORKSPACE_INFO, { workspaceId }) },
        { label: "All Agents", path: getUrl(ROUTES.PIPELINES, { workspaceId }) },
      ]}
      headerChildren={
        pipelines.length > 0 && (
          <Button size="large" type="primary" onClick={handleNewPipeline}>
            <FaPlus />
            Create Agent
          </Button>
        )
      }
    >
      {pipelines.length ? (
        <PipelinesGrid>
          {pipelines.map(({ id, name, updated_at, knowledge_base }) => (
            <PipelineCard
              id={id}
              key={id}
              knowledgeBase={knowledge_base?.name}
              lastModified={new Date(updated_at).toLocaleDateString()}
              title={name}
            />
          ))}
        </PipelinesGrid>
      ) : (
        <CreateFirstPipelineContainer onClick={handleNewPipeline}>
          <p>You do not have any agent yet! Click here to create your first agent now!</p>
        </CreateFirstPipelineContainer>
      )}
    </Layout>
  );
};

export default PipelinesPage;
