// TODO: check and refactor this file
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Row, Col, Statistic } from "antd";
import { FaCog } from "react-icons/fa";

import Button from "../../components/Button/Button";
import Layout from "../../components/Layout/Layout";
import { fetchPipelineById } from "../../connectors/api";
import { ROUTES } from "../../constants/routes";
import { getUrl } from "../../helpers/navigation";
import useWorkspaceContext from "../../hooks/useWorkspaceContext";

const InfoSection = styled.div`
  background: white;
  padding: 24px;
  border-radius: 10px;
  margin-bottom: 24px;

  .label {
    color: #8f9bba;
    font-size: 14px;
    margin-bottom: 4px;
  }

  .value {
    color: #2b3674;
    font-size: 16px;
    font-weight: 500;
  }
`;

const StatCard = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 16px;

  .stat-icon {
    background: #f4f7fe;
    padding: 12px;
    border-radius: 50%;
    color: #4318ff;
  }

  .stat-change {
    color: #05cd99;
    font-size: 12px;
  }
`;

const PipelineInfoPage = () => {
  const [pipeline, setPipeline] = useState(null);

  const { workspaceId, pipelineId } = useParams();
  const navigate = useNavigate();

  const { workspace } = useWorkspaceContext(workspaceId);

  useEffect(() => {
    const loadPipeline = async () => {
      const data = await fetchPipelineById(workspaceId, pipelineId);
      setPipeline(data);
      console.log(data);
    };
    loadPipeline();
  }, [workspaceId, pipelineId]);

  return (
    <Layout
      title={`Agent Information`}
      breadcrumbItems={[
        { label: "Workspaces", path: ROUTES.WORKSPACES },
        { label: workspace?.name, path: getUrl(ROUTES.WORKSPACE_INFO, { workspaceId }) },
        { label: "All Agents", path: getUrl(ROUTES.PIPELINES, { workspaceId }) },
        { label: pipeline?.name, path: getUrl(ROUTES.PIPELINE_INFO, { workspaceId, pipelineId }) },
        { label: "Information", path: getUrl(ROUTES.PIPELINE_INFO, { workspaceId, pipelineId }) },
      ]}
      headerChildren={
        <Button
          size="large"
          type="primary"
          onClick={() => navigate(getUrl(ROUTES.PIPELINE_EDIT, { workspaceId, pipelineId }))}
        >
          <FaCog /> Edit Agent
        </Button>
      }
    >
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <InfoSection>
            <div>
              <div className="label">Agent Name</div>
              <div className="value">{pipeline?.name ?? "-"}</div>
            </div>
            <div style={{ marginTop: 16 }}>
              <div className="label">Type</div>
              <div className="value">{pipeline?.type ?? "-"}</div>
            </div>
            <div style={{ marginTop: 16 }}>
              <div className="label">Status</div>
              <div className="value">{pipeline?.status ?? "-"}</div>
            </div>
            <div style={{ marginTop: 16 }}>
              <div className="label">Runs last month</div>
              <div className="value">{pipeline?.runs_last_month ?? "-"}</div>
            </div>
          </InfoSection>
        </Col>
        <Col span={12}>
          <InfoSection>
            <div>
              <div className="label">Created by</div>
              <div className="value">{pipeline?.created_by?.username ?? "-"}</div>
            </div>
            <div style={{ marginTop: 16 }}>
              <div className="label">Created at</div>
              <div className="value">{new Date(pipeline?.created_at).toLocaleDateString()}</div>
            </div>
            <div style={{ marginTop: 16 }}>
              <div className="label">Last Modified</div>
              <div className="value">{new Date(pipeline?.updated_at).toLocaleDateString()}</div>
            </div>
            <div style={{ marginTop: 16 }}>
              <div className="label">Last Run</div>
              <div className="value">
                {pipeline?.last_run ? new Date(pipeline.last_run).toLocaleDateString() : "Never"}
              </div>
            </div>
          </InfoSection>
        </Col>
      </Row>

      <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
        <Col span={8}>
          <StatCard>
            <div className="stat-icon">�</div>
            <div>
              <Statistic title="Tokens" value={pipeline?.number_of_tokens ?? 0} />
              <div className="stat-change">+23% since last month</div>
            </div>
          </StatCard>
        </Col>
        <Col span={8}>
          <StatCard>
            <div className="stat-icon">⚡</div>
            <div>
              <Statistic title="Prompt Tokens" value={pipeline?.number_of_prompt_tokens ?? 0} suffix="s" />
              <div className="stat-change">+23% since last month</div>
            </div>
          </StatCard>
        </Col>
        <Col span={8}>
          <StatCard>
            <div className="stat-icon">✨</div>
            <div>
              <Statistic title="Completion Tokens" value={pipeline?.number_of_completion_tokens ?? 0} suffix="%" />
              <div className="stat-change">+23% since last month</div>
            </div>
          </StatCard>
        </Col>
      </Row>
    </Layout>
  );
};

export default PipelineInfoPage;
