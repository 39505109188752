import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { notification } from "antd";
import styled from "styled-components";
import { FaCog } from "react-icons/fa";

import WorkspaceInfoKnowledgeBases from "./WorkspaceInfoKnowledgeBases";
import WorkspaceInfoPipelines from "./WorkspaceInfoPipelines";
import Button from "../../components/Button/Button";
import Layout from "../../components/Layout/Layout";
import { fetchWorkspaceById } from "../../connectors/api";
import { getUrl } from "../../helpers/navigation";
import { ROUTES } from "../../constants/routes";
import useWorkspaceContext from "../../hooks/useWorkspaceContext";

const WorkspacePageWrapper = styled.div`
  .section-title {
    width: fit-content;
    font-size: 28px;
    margin: 48px 0 24px;
    font-weight: 600;
    color: #000000;
    letter-spacing: -0.5px;
  }

  .items-grid {
    display: flex;
    gap: 20px;
    justify-content: left;
    flex-wrap: wrap;
  }

  .no-items-text {
    background: #f8f8f8;
    border-radius: 12px;
    border: 1px dashed #e8e8e8;
    color: #8c8c8c;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    margin: 32px 0;
    padding: 24px;
    text-align: center;
  }
`;

const WorkspacesInfoPage = () => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();

  const { setWorkspace } = useWorkspaceContext();

  const [isLoading, setIsLoading] = useState(true);
  const [workspaceInfo, setWorkspaceInfo] = useState({});

  useEffect(() => {
    const fetchWorkspaceData = async () => {
      try {
        const response = await fetchWorkspaceById(workspaceId);
        setWorkspaceInfo(response);
        setWorkspace(response);
        setIsLoading(false);
      } catch (error) {
        notification.error({
          message: "Failed to load workspace information!",
          description: "Please reload the page, or if the problem persists, contact the Somia team for assistance.",
        });
        console.error("Failed to fetch workspace data", error);
      }
    };

    fetchWorkspaceData();
  }, [workspaceId, setWorkspace]);

  const { knowledge_bases, name, pipelines } = workspaceInfo;

  return (
    <Layout
      title="Workspace Overview"
      breadcrumbItems={[
        { label: "Workspaces", path: ROUTES.WORKSPACES },
        { label: name, path: getUrl(ROUTES.WORKSPACE_INFO, { workspaceId }) },
      ]}
      isLoading={isLoading}
      workspaceName={name}
      headerChildren={
        <Button onClick={() => navigate(getUrl(ROUTES.WORKSPACE_EDIT, { workspaceId }))} size="large" type="primary">
          <FaCog /> Edit Workspace
        </Button>
      }
    >
      <WorkspacePageWrapper>
        <WorkspaceInfoPipelines pipelines={pipelines} workspaceId={workspaceId} />
        <WorkspaceInfoKnowledgeBases knowledgeBases={knowledge_bases} workspaceId={workspaceId} />
      </WorkspacePageWrapper>
    </Layout>
  );
};

export default WorkspacesInfoPage;
