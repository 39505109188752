import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PauseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Progress, Table, Tag } from "antd";
import dayjs from "dayjs";

const renderStatusTag = (status) => {
  switch (status) {
    case "FINISHED":
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Success
        </Tag>
      );

    case "FAILED":
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          Failed
        </Tag>
      );

    case "PAUSED":
      return (
        <Tag icon={<PauseCircleOutlined />} color="warning">
          Paused
        </Tag>
      );

    default:
      return (
        <Tag icon={<SyncOutlined spin />} color="processing">
          Running
        </Tag>
      );
  }
};

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text) => renderStatusTag(text),
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (text) => (
      <div title={dayjs(text).format("DD of MMMM YYYY, HH:mm:ss")}>
        {dayjs(text).format("DD/MM/YYYY")}
      </div>
    ),
  },
  {
    title: "Test accuracy",
    dataIndex: "testAccuracy",
    key: "testAccuracy",
    render: (text) => (
      <div title={`${Math.round(text * 100)}%`}>
        <Progress percent={text * 100} size="small" showInfo={false} />
      </div>
    ),
  },
];

const ModelsTable = ({ data, noScroll }) => (
  <Table
    columns={columns}
    dataSource={data}
    pagination={false}
    rowKey="id"
    scroll={noScroll ? null : { y: 230 }}
    size="small"
  />
);

export default ModelsTable;
