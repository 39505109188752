import React, { useEffect } from "react";
import { Modal, Form, Input, notification } from "antd";

import { createWorkspace } from "../../connectors/api";
import Button from "../../components/Button/Button";

const SubmitButton = ({ form, children }) => {
  const [submittable, setSubmittable] = React.useState(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable}>
      {children}
    </Button>
  );
};

const WorkspacesCreateModal = ({ visible, onCancel, onSubmitSuccess }) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      const response = await createWorkspace(values);
      if (response?.workspace_id) {
        onSubmitSuccess();
        form.resetFields();
        notification.success({ message: "New workspace was created successfully!" });
      } else {
        throw new Error("Invalid response format from create workspace: " + JSON.stringify(response, 2, 2));
      }
    } catch (error) {
      notification.error({
        message: "Failed to create workspace!",
        description: "Please try again later, or if the problem persists, contact the Somia team for assistance.",
      });
      console.error("Failed to create workspace", error);
    }
  };

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  return (
    <Modal
      title="Create New Workspace"
      open={visible}
      onCancel={handleCancel}
      footer={null}
      styles={{
        header: { marginBottom: 25 },
      }}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          name="name"
          label="Workspace name"
          rules={[{ required: true, message: "Please input workspace name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: "Please input workspace description!" }]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item>
          <SubmitButton form={form}>Create workspace</SubmitButton>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default WorkspacesCreateModal;
