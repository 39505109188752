import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { Button } from "antd";

import ProjectCard from "../../components/ProjectCard/ProjectCard";
import Layout from "../../components/Layout/Layout";
import { fetchProjectsList } from "../../connectors/api";
import { getUrl } from "../../helpers/navigation";
import { ROUTES } from "../../constants/routes";

const ProjectGrid = styled.div`
  display: flex;
  gap: 20px;
  justify-content: left;
  flex-wrap: wrap;
`;

const ProjectsList = () => {
  const { workspaceId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetchProjectsList(workspaceId);
        setProjects(response.projects);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch list of projects", error);
        setIsLoading(false);
      }
    };

    fetchProjects();
  }, []);

  return (
    <Layout
      title="Your projects"
      isLoading={isLoading}
      headerChildren={
        <Link to={getUrl(ROUTES.NEW_PROJECT, { workspaceId })}>
          <Button size="large" type="primary">
            New Project
          </Button>
        </Link>
      }
    >
      <ProjectGrid>
        {projects.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </ProjectGrid>
    </Layout>
  );
};

export default ProjectsList;
