import React, { useEffect } from "react";
import { Modal, Form, Input, notification } from "antd";

import Button from "../../components/Button/Button";
import { createKnowledgeBase } from "../../connectors/api";

const SubmitButton = ({ form, children }) => {
  const [submittable, setSubmittable] = React.useState(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable}>
      {children}
    </Button>
  );
};

const KnowledgeBaseCreateModal = ({ visible, onCancel, onSubmitSuccess, workspaceId }) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      const response = await createKnowledgeBase(workspaceId, {
        name: values.name,
      });
      if (response?.knowledge_base_id) {
        onSubmitSuccess();
        form.resetFields();
        notification.success({ message: "Knowledge Base was created successfully!" });
      } else {
        throw new Error("Invalid response format from create knowledge base: " + JSON.stringify(response, 2, 2));
      }
    } catch (error) {
      notification.error({
        message: "Failed to create knowledge base!",
        description: "Please try again later, or if the problem persists, contact the Somia team for assistance.",
      });
      console.error("Failed to create knowledge base", error);
    }
  };

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  return (
    <Modal
      title="Create New Knowledge Base"
      open={visible}
      onCancel={handleCancel}
      footer={null}
      styles={{
        header: { marginBottom: 25 },
      }}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          name="name"
          label="Knowledge Base Name"
          rules={[{ required: true, message: "Please input knowledge base name!" }]}
        >
          <Input placeholder="Enter knowledge base name" />
        </Form.Item>
        <Form.Item>
          <SubmitButton form={form}>Create Knowledge Base</SubmitButton>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default KnowledgeBaseCreateModal;
