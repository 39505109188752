// TODO: check and refactor this file
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Input, Row, Col, Card, notification, Modal, Form } from "antd";

import Layout from "../../components/Layout/Layout";
import { createPipeline, fetchPipelineTemplates } from "../../connectors/api";
import { ROUTES } from "../../constants/routes";
import { getUrl } from "../../helpers/navigation";
import useWorkspaceContext from "../../hooks/useWorkspaceContext";

const PageWrapper = styled.div`
  .section-title {
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 500;
  }

  .pipeline-card {
    border-radius: 10px;
    cursor: pointer;
    height: 200px;
    transition: all 0.3s;
    position: relative;
    padding: 20px;

    &:hover {
      border-color: #4318ff;
    }

    &.selected {
      border-color: #4318ff;
      background: #f4f7fe;
    }

    .info-icon {
      position: absolute;
      top: 16px;
      right: 16px;
      color: #8f9bba;
    }

    .type-label {
      position: absolute;
      bottom: 16px;
      right: 16px;
      color: #8f9bba;
    }
  }
`;

const baseTemplate = {
  id: "from-scratch",
  name: "Create from scratch",
  description: "Create your own Agent from scratch",
  type: "agent",
};

const NewPipelinePage = () => {
  const [templates, setTemplates] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const { workspaceId } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { workspace } = useWorkspaceContext(workspaceId);

  useEffect(() => {
    const loadTemplates = async () => {
      const data = await fetchPipelineTemplates();
      setTemplates(data);
    };
    loadTemplates();
  }, []);

  const handleCardClick = (template) => {
    setSelectedTemplate(template);
    setIsModalVisible(true);
  };

  const handleModalSubmit = async () => {
    try {
      const values = await form.validateFields();

      await createPipeline(workspaceId, {
        name: values.name,
        description: values.description,
        template_id: selectedTemplate?.id || 1,
        status: "draft",
      });

      notification.success({
        message: "Agent created successfully",
      });
      navigate(getUrl(ROUTES.PIPELINES, { workspaceId }));
    } catch (error) {
      if (error.errorFields) {
        notification.error({
          message: "Please fill in all required fields",
        });
      } else {
        notification.error({
          message: "Error creating agent",
          description: error.message,
        });
      }
    }
  };

  return (
    <Layout
      title="Create New Agent"
      breadcrumbItems={[
        { label: "Workspaces", path: ROUTES.WORKSPACES },
        { label: workspace?.name, path: getUrl(ROUTES.WORKSPACE_INFO, { workspaceId }) },
        { label: "All Agents", path: getUrl(ROUTES.PIPELINES, { workspaceId }) },
        { label: "Create New Agent", path: getUrl(ROUTES.PIPELINE_NEW, { workspaceId }) },
      ]}
    >
      <PageWrapper>
        <div className="section-title">Create your Agent from scratch</div>
        <Row gutter={[24, 24]}>
          <Col span={8} key={baseTemplate.id}>
            <Card
              className={`pipeline-card ${selectedTemplate?.id === baseTemplate.id ? "selected" : ""}`}
              onClick={() => handleCardClick(baseTemplate)}
            >
              {" "}
              <h3>{baseTemplate.name}</h3>
              <p>{baseTemplate.description}</p>
              <div className="type-label">Agent</div>
            </Card>
          </Col>
        </Row>

        <div className="section-title" style={{ marginTop: "48px" }}>
          Try out our Agents
        </div>
        <Row gutter={[24, 24]}>
          {templates.map((template) => (
            <Col span={8} key={template.id}>
              <Card
                className={`pipeline-card ${selectedTemplate?.id === template.id ? "selected" : ""}`}
                onClick={() => handleCardClick(template)}
              >
                <h3>{template.name}</h3>
                <p>{template.description}</p>
                <div className="type-label">Agent</div>
              </Card>
            </Col>
          ))}
        </Row>

        <Modal
          title="Create Agent"
          open={isModalVisible}
          onOk={handleModalSubmit}
          onCancel={() => setIsModalVisible(false)}
          okText="Create"
          okButtonProps={{ className: "submit-button" }}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="name"
              label="Agent Name"
              rules={[{ required: true, message: "Please enter a agent name" }]}
            >
              <Input placeholder="Enter agent name" />
            </Form.Item>
            <Form.Item name="description" label="Description (Optional)">
              <Input.TextArea placeholder="Enter description" />
            </Form.Item>
          </Form>
        </Modal>
      </PageWrapper>
    </Layout>
  );
};

export default NewPipelinePage;
