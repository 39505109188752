// TODO: check and refactor this file
import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Spin } from "antd";

import Layout from "../../components/Layout/Layout";
import { fetchPipelineById } from "../../connectors/api";
import { fetchLoginRagFlow } from "../../connectors/auth";
import { RAGFLOW_HOST_URL } from "../../constants/endpoints";
import { ROUTES } from "../../constants/routes";
import { getUrl } from "../../helpers/navigation";
import useWorkspaceContext from "../../hooks/useWorkspaceContext";

const MAX_NUMBER_OF_RETRIES = 5;

const ConfigIframeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 50px);
  top: -80px;
`;

const ConfigIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  opacity: ${(props) => (props.$isLoading ? 0 : 1)};
  transition: opacity 0.3s ease;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  opacity: ${(props) => (props.$isLoading ? 1 : 0)};
  visibility: ${(props) => (props.$isLoading ? "visible" : "hidden")};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const ParentContainer = styled.div`
  overflow: hidden;
`;

const FailedLoadingWrapper = styled.div`
  max-width: 600px;
  margin: auto;
  text-align: center;
  padding: 40px 20px;

  .failed-loading-title {
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
  }

  .failed-loading-subtitle {
    line-height: 1.6;
    color: #666;
    font-size: 16px;
  }
`;

const AgentConfigurationPage = () => {
  const [authToken, setAuthToken] = useState(null);
  const [error, setError] = useState(null);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [pipeline, setPipeline] = useState(null);
  const [ragflowAgentId, setRagflowAgentId] = useState(null);

  const { workspaceId, pipelineId } = useParams();

  const iframeRef = useRef(null);

  const { workspace } = useWorkspaceContext(workspaceId);

  const loginRagFlow = async () => {
    let count = 0;
    while (count < MAX_NUMBER_OF_RETRIES) {
      try {
        count += 1;
        const response = await fetchLoginRagFlow(); // Call the new login function
        console.log("Successfully logged in to RagFlow");
        console.log("Response:", response);
        console.log("Auth key:", response.authorization_key);
        setAuthToken(response.authorization_key);
        return true;
      } catch (error) {
        console.error("Retrying login to RagFlow", count);
      }
    }
    console.error("Failed to log in to RagFlow after multiple attempts");
    return false;
  };

  useEffect(() => {
    const fetchPipelineInfo = async () => {
      try {
        const response = await fetchPipelineById(workspaceId, pipelineId);
        setPipeline(response);
        setIsLoading(false);
        console.log("Agent info fetched:", response);
        setRagflowAgentId(response.ragflow_agent_id);
      } catch (error) {
        console.error("Failed to fetch Agent info:", error);
        setError(error);
        setIsLoading(false);
      }
    };

    loginRagFlow();
    fetchPipelineInfo();

    return () => {};
  }, [workspaceId, pipelineId]);

  const handleIframeLoad = () => {
    setTimeout(() => {
      setIframeLoading(false);
    }, 3000);
  };

  if (error) {
    return (
      <Layout title="Agent Configuration" isLoading={isLoading}>
        <FailedLoadingWrapper>
          <h2 className="failed-loading-title">Failed to load RagFlow configuration</h2>
          <p className="failed-loading-subtitle">
            Please try to reload this page or contact us to resolve your problem as soon as possible! Sorry for
            inconveniences and have a great day!
          </p>
        </FailedLoadingWrapper>
      </Layout>
    );
  }

  return (
    <Layout
      title="Agent Configuration"
      breadcrumbItems={[
        { label: "Workspaces", path: ROUTES.WORKSPACES },
        { label: workspace?.name, path: getUrl(ROUTES.WORKSPACE_INFO, { workspaceId }) },
        { label: "All Agents", path: getUrl(ROUTES.PIPELINES, { workspaceId }) },
        { label: pipeline?.name, path: getUrl(ROUTES.PIPELINE_INFO, { workspaceId, pipelineId }) },
        { label: "Configuration", path: getUrl(ROUTES.AGENT_CONFIGURATION, { workspaceId, pipelineId }) },
      ]}
      isLoading={isLoading}
      initialCollapsed={true}
    >
      <ParentContainer>
        <ConfigIframeWrapper>
          <LoadingOverlay $isLoading={iframeLoading}>
            <Spin size="large" />
          </LoadingOverlay>
          <ConfigIframe
            ref={iframeRef}
            src={`${RAGFLOW_HOST_URL}/flow/${ragflowAgentId}?auth=${encodeURIComponent(authToken)}`}
            title="Agent configuration"
            onLoad={handleIframeLoad}
            $isLoading={iframeLoading}
          />
        </ConfigIframeWrapper>
      </ParentContainer>
    </Layout>
  );
};

export default AgentConfigurationPage;
