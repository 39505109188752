import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Row, Col } from "antd";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout";
import { fetchWorkspaceById } from "../../connectors/api";
import ProjectCard from "../../components/ProjectCard/ProjectCard";

const ProjectsPageWrapper = styled.div`
  .header-button {
    background: linear-gradient(135deg, #4318ff 0%, #9747ff 100%);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 24px;
    transition: all 0.3s ease;

    &:hover {
      background: linear-gradient(135deg, #3311cc 0%, #8033ff 100%);
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(67, 24, 255, 0.2);
    }
  }

  .section-title {
    font-size: 28px;
    margin: 48px 0 24px;
    font-weight: 600;
    background: linear-gradient(135deg, #1a1a1a 0%, #4318ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -0.5px;
  }

  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 24px;
    padding: 8px 4px;
  }

  .no-items-text {
    color: #8c8c8c;
    font-size: 16px;
    margin: 32px 0;
    text-align: center;
    font-weight: 500;
    background: #f8f8f8;
    padding: 24px;
    border-radius: 12px;
    border: 1px dashed #e8e8e8;
  }
`;

const Projects = () => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const [workspaceInfo, setWorkspaceInfo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchWorkspaceById(workspaceId);
      setWorkspaceInfo(data);
    };
    fetchData();
  }, [workspaceId]);

  return (
    <Layout
      title={`${workspaceInfo?.name || "Projects"}`}
      headerChildren={
        <Button className="header-button" onClick={() => navigate(-1)} size="large">
          Back
        </Button>
      }
    >
      <ProjectsPageWrapper>
        <div>
          <h2 className="section-title">Recent Projects</h2>
          {workspaceInfo?.projects?.length > 0 ? (
            <Row className="card-grid">
              {workspaceInfo.projects.map((project) => (
                <Col key={project.id}>
                  <ProjectCard project={{ ...project, workspaceId }} />
                </Col>
              ))}
            </Row>
          ) : (
            <p className="no-items-text">No projects created yet. Start by creating your first project!</p>
          )}
        </div>
      </ProjectsPageWrapper>
    </Layout>
  );
};

export default Projects;
