import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import Layout from "../components/Layout/Layout";
import { fetchCheckToken } from "../connectors/auth";
import { ROUTES } from "../constants/routes";

const StyledLoading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const withAuth = (WrappedComponent) => {
  const AuthenticationCheck = (props) => {
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
      const validateToken = async () => {
        try {
          console.log("Validating token");
          const response = await fetchCheckToken();
          console.log("Token validation response:", response);
          setIsLoading(false);
        } catch (error) {
          console.log("Error validating token:", error);
          navigate(ROUTES.LOGIN);
        }
      };

      validateToken();
    }, [navigate]);

    if (isLoading) {
      return (
        <Layout>
          <StyledLoading>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
          </StyledLoading>
        </Layout>
      );
    }
    return <WrappedComponent {...props} />;
  };

  return AuthenticationCheck;
};

export default withAuth;
