const HOME = "/";
const LOGIN = "/login";
const AGENT_CONFIGURATION = "/workspaces/:workspaceId/pipelines/:pipelineId/configuration";
const AGENT_TESTING = "/workspaces/:workspaceId/pipelines/:pipelineId/testing";
const AUTOMATION = "/workspaces/:workspaceId/automation";
const KNOWLEDGE_BASE = "/workspaces/:workspaceId/knowledge-base";
const KNOWLEDGE_BASE_BY_ID = "/workspaces/:workspaceId/knowledge-base/:knowledgeBaseId";
const NEW_PROJECT = "/workspaces/:workspaceId/projects/new-project";
const PIPELINES = "/workspaces/:workspaceId/pipelines";
const PIPELINE_EDIT = "/workspaces/:workspaceId/pipelines/:pipelineId/edit";
const PIPELINE_INFO = "/workspaces/:workspaceId/pipelines/:pipelineId";
const PIPELINE_NEW = "/workspaces/:workspaceId/pipelines/new";
const PROJECTS = "/workspaces/:workspaceId/projects/list";
const PROJECTS_PAGE = "/workspaces/:workspaceId/projects";
const PROJECT_DASHBOARD = "/workspaces/:workspaceId/projects/:id/dashboard";
const PROJECT_DATA = "/workspaces/:workspaceId/projects/:id/data";
const PROJECT_MODELS = "/workspaces/:workspaceId/projects/:id/models";
const UNDER_CONSTRUCTION = "/workspaces/:workspaceId/under-construction";
const USER_PROFILE = "/profile";
const WORKSPACES = "/workspaces";
const WORKSPACE_EDIT = "/workspaces/:workspaceId/edit";
const WORKSPACE_INFO = "/workspaces/:workspaceId";

export const ROUTES = {
  AGENT_CONFIGURATION,
  AGENT_TESTING,
  AUTOMATION,
  HOME,
  KNOWLEDGE_BASE,
  KNOWLEDGE_BASE_BY_ID,
  LOGIN,
  NEW_PROJECT,
  PIPELINES,
  PIPELINE_EDIT,
  PIPELINE_INFO,
  PIPELINE_NEW,
  PROJECTS,
  PROJECTS_PAGE,
  PROJECT_DASHBOARD,
  PROJECT_DATA,
  PROJECT_MODELS,
  UNDER_CONSTRUCTION,
  USER_PROFILE,
  WORKSPACES,
  WORKSPACE_EDIT,
  WORKSPACE_INFO,
};
