const HOME = "/";
const LOGIN = "/login";
const PROJECTS = "/projects";
const PROJECT_DASHBOARD = "/projects/:id/dashboard";
const PROJECT_DATA = "/projects/:id/data";
const PROJECT_MODELS = "/projects/:id/models";
const NEW_PROJECT = "/projects/new-project";

export const ROUTES = {
  HOME,
  LOGIN,
  NEW_PROJECT,
  PROJECTS,
  PROJECT_DASHBOARD,
  PROJECT_DATA,
  PROJECT_MODELS,
};
