import { InfoCircleOutlined } from "@ant-design/icons";
import { Avatar, Card, Flex, Statistic, Tooltip } from "antd";

const getSuffixByType = (type) => {
  switch (type) {
    case "percent":
      return "%";

    case "points":
      return "/ 10";

    default:
      return null;
  }
};

const Infobox = ({ icon, description, title, value, type }) => {
  const valueToShow = type === "percent" ? Math.round(value * 100) : value;

  const titleToRender = (
    <>
      {title}
      {description && (
        <Tooltip title={description}>
          <InfoCircleOutlined style={{ marginLeft: 5 }} />
        </Tooltip>
      )}
    </>
  );

  return (
    <Card>
      <Flex>
        <Avatar
          size={64}
          icon={icon}
          style={{
            backgroundColor: "#F4F7FE",
            color: "#4318FF",
            marginRight: "25px",
          }}
        />
        <Statistic
          suffix={getSuffixByType(type)}
          title={titleToRender}
          value={valueToShow}
          valueStyle={{ fontWeight: "bold" }}
        />
      </Flex>
    </Card>
  );
};

export default Infobox;
