import { Table } from "antd";

const renderBoolean = (text) => (text === true ? "True" : "False");

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Annotation",
    dataIndex: "annotation",
    key: "annotation",
    render: renderBoolean,
  },
  {
    title: "Prediction",
    dataIndex: "prediction",
    key: "prediction",
    render: renderBoolean,
  },
  {
    title: "Test set",
    dataIndex: "testSet",
    key: "testSet",
    render: renderBoolean,
  },
];

const ImagesTable = ({ data }) => (
  <Table
    columns={columns}
    dataSource={data}
    pagination={false}
    rowKey="id"
    scroll={{ y: 230 }}
    size="small"
  />
);

export default ImagesTable;
