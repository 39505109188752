import React from "react";
import { Navigate, BrowserRouter as Router, Route, Routes } from "react-router-dom";

import WorkspaceProvider from "./context/Workspace/WorkspaceProvider";

import AgentConfigurationPage from "./pages/Agent/AgentConfigurationPage";
import AgentTestingPage from "./pages/Agent/AgentTestingPage";
import AutomationPage from "./pages/Automation/AutomationPage";
import DashboardPage from "./pages/ProjectDashboard/DashboardPage";
import DataPage from "./pages/ProjectData/DataPage";
import KnowledgeBaseByIdPage from "./pages/KnowledgeBasePage/KnowledgeBaseInfoPage";
import KnowledgeBasePage from "./pages/KnowledgeBasePage/KnowledgeBasePage";
import LoginPage from "./pages/Auth/LoginPage/LoginPage";
import ModelsPage from "./pages/ProjectModels/ModelsPage";
import NewPipelinePage from "./pages/ProjectPipelines/NewPipelinePage";
import NewProjectPage from "./pages/newProject/NewProjectPage";
import PipelineEditPage from "./pages/ProjectPipelines/EditPipelinePage";
import PipelineInfoPage from "./pages/ProjectPipelines/PipelineInfoPage";
import PipelinesPage from "./pages/ProjectPipelines/PipelinesPage";
import ProjectsList from "./pages/ProjectsList/ProjectsList";
import ProjectsPage from "./pages/Projects/Projects";
import UnderConstructionPage from "./pages/General/UnderConstruction";
import UserConfigurationPage from "./pages/UserConfiguration/UserConfiguration";
import WorkspaceEditPage from "./pages/Workspaces/WorkspacesEditPage";
import WorkspacesInfoPage from "./pages/WorkspaceInfo/WorkspaceInfo";
import WorkspacesPage from "./pages/Workspaces/WorkspacesPage";
import { ROUTES } from "./constants/routes";
import withAuth from "./HOCs/withAuth";

const {
  HOME,
  LOGIN,
  AGENT_CONFIGURATION,
  AGENT_TESTING,
  AUTOMATION,
  KNOWLEDGE_BASE,
  KNOWLEDGE_BASE_BY_ID,
  NEW_PROJECT,
  PIPELINES,
  PIPELINE_EDIT,
  PIPELINE_INFO,
  PIPELINE_NEW,
  PROJECTS,
  PROJECTS_PAGE,
  PROJECT_DASHBOARD,
  PROJECT_DATA,
  PROJECT_MODELS,
  UNDER_CONSTRUCTION,
  USER_PROFILE,
  WORKSPACES,
  WORKSPACE_EDIT,
  WORKSPACE_INFO,
} = ROUTES;

const PrivateAgentConfigurationPage = withAuth(AgentConfigurationPage);
const PrivateAgentTestingPage = withAuth(AgentTestingPage);
const PrivateAutomationPage = withAuth(AutomationPage);
const PrivateDashboardPage = withAuth(DashboardPage);
const PrivateDataPage = withAuth(DataPage);
const PrivateKnowledgeBaseByIdPage = withAuth(KnowledgeBaseByIdPage);
const PrivateKnowledgeBasePage = withAuth(KnowledgeBasePage);
const PrivateModelsPage = withAuth(ModelsPage);
const PrivateNewPipelinePage = withAuth(NewPipelinePage);
const PrivateNewProjectPage = withAuth(NewProjectPage);
const PrivatePipelineEditPage = withAuth(PipelineEditPage);
const PrivatePipelineInfoPage = withAuth(PipelineInfoPage);
const PrivatePipelinesPage = withAuth(PipelinesPage);
const PrivateProjectsPage = withAuth(ProjectsPage);
const PrivateProjectsPageList = withAuth(ProjectsList);
const PrivateUnderConstructionPage = withAuth(UnderConstructionPage);
const PrivateUserConfigurationPage = withAuth(UserConfigurationPage);
const PrivateWorkspaceEditPage = withAuth(WorkspaceEditPage);
const PrivateWorkspaceInfoPage = withAuth(WorkspacesInfoPage);
const PrivateWorkspacesPage = withAuth(WorkspacesPage);

const App = () => (
  <div className="app">
    <WorkspaceProvider>
      <Router>
        <Routes>
          <Route path={HOME} element={<Navigate to={WORKSPACES} replace />} />
          <Route path={LOGIN} element={<LoginPage />} />
          <Route path={AGENT_CONFIGURATION} element={<PrivateAgentConfigurationPage />} />
          <Route path={AGENT_TESTING} element={<PrivateAgentTestingPage />} />
          <Route path={AUTOMATION} element={<PrivateAutomationPage />} />
          <Route path={KNOWLEDGE_BASE_BY_ID} element={<PrivateKnowledgeBaseByIdPage />} />
          <Route path={KNOWLEDGE_BASE} element={<PrivateKnowledgeBasePage />} />
          <Route path={NEW_PROJECT} element={<PrivateNewProjectPage />} />
          <Route path={PIPELINES} element={<PrivatePipelinesPage />} />
          <Route path={PIPELINE_EDIT} element={<PrivatePipelineEditPage />} />
          <Route path={PIPELINE_INFO} element={<PrivatePipelineInfoPage />} />
          <Route path={PIPELINE_NEW} element={<PrivateNewPipelinePage />} />
          <Route path={PROJECTS_PAGE} element={<PrivateProjectsPage />} />
          <Route path={PROJECTS} element={<PrivateProjectsPageList />} />
          <Route path={PROJECT_DASHBOARD} element={<PrivateDashboardPage />} />
          <Route path={PROJECT_DATA} element={<PrivateDataPage />} />
          <Route path={PROJECT_MODELS} element={<PrivateModelsPage />} />
          <Route path={UNDER_CONSTRUCTION} element={<PrivateUnderConstructionPage />} />
          <Route path={USER_PROFILE} element={<PrivateUserConfigurationPage />} />
          <Route path={WORKSPACES} element={<PrivateWorkspacesPage />} />
          <Route path={WORKSPACE_EDIT} element={<PrivateWorkspaceEditPage />} />
          <Route path={WORKSPACE_INFO} element={<PrivateWorkspaceInfoPage />} />
        </Routes>
      </Router>
    </WorkspaceProvider>
  </div>
);

export default App;
