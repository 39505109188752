// TODO: check and refactor this file
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Form, Input, Row, Col, notification } from "antd";

import Button from "../../components/Button/Button";
import Layout from "../../components/Layout/Layout";
import { fetchPipelineById, updatePipeline, deletePipeline } from "../../connectors/api";
import { ROUTES } from "../../constants/routes";
import { getUrl } from "../../helpers/navigation";
import useWorkspaceContext from "../../hooks/useWorkspaceContext";

const EditPageWrapper = styled.div`
  .info-box {
    background: white;
    padding: 24px;
    border-radius: 10px;
    margin-bottom: 24px;
  }

  .label {
    color: #8f9bba;
    font-size: 14px;
    margin-bottom: 4px;
  }

  .value {
    color: #2b3674;
    font-size: 16px;
  }

  .delete-button {
    background-color: #ff0000;
    color: white;
    border: none;
    &:hover {
      background-color: #ff0000;
      color: white;
      transform: none;
    }
  }
`;

const EditPipelinePage = () => {
  const [pipeline, setPipeline] = useState(null);

  const { workspaceId, pipelineId } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { workspace } = useWorkspaceContext(workspaceId);

  useEffect(() => {
    const loadPipeline = async () => {
      const data = await fetchPipelineById(workspaceId, pipelineId);
      setPipeline(data);
      form.setFieldsValue({
        name: data.name,
        type: data.type,
        status: data.status,
      });
    };
    loadPipeline();
  }, [workspaceId, pipelineId, form]);

  const handleSave = async (values) => {
    try {
      await updatePipeline(workspaceId, pipelineId, values);
      notification.success({ message: "Agent updated successfully" });
      navigate(getUrl(ROUTES.PIPELINE_INFO, { workspaceId, pipelineId }));
    } catch (error) {
      notification.error({ message: "Error updating agent" });
    }
  };

  const handleDelete = async () => {
    try {
      await deletePipeline(workspaceId, pipelineId);
      notification.success({ message: "Agent deleted successfully" });
      navigate(getUrl(ROUTES.PIPELINES, { workspaceId }));
    } catch (error) {
      notification.error({ message: "Error deleting agent" });
    }
  };

  return (
    <Layout
      title="Edit Agent"
      breadcrumbItems={[
        { label: "Workspaces", path: ROUTES.WORKSPACES },
        { label: workspace?.name, path: getUrl(ROUTES.WORKSPACE_INFO, { workspaceId }) },
        { label: "All Agents", path: getUrl(ROUTES.PIPELINES, { workspaceId }) },
        { label: pipeline?.name, path: getUrl(ROUTES.PIPELINE_INFO, { workspaceId, pipelineId }) },
        { label: "Edit Agent", path: getUrl(ROUTES.PIPELINE_EDIT, { workspaceId, pipelineId }) },
      ]}
    >
      <EditPageWrapper>
        <Form form={form} layout="vertical" onFinish={handleSave}>
          <Row gutter={24}>
            <Col span={12}>
              <div className="info-box">
                <Form.Item name="name" label="Agent Name" rules={[{ required: true }]}>
                  <Input placeholder="Enter agent name" />
                </Form.Item>
                <Form.Item name="type" label="Type">
                  <Input disabled />
                </Form.Item>
                <Form.Item name="status" label="Status">
                  <Input disabled />
                </Form.Item>
                <Form.Item name="runs_last_month" label="Runs last month">
                  <Input disabled value={pipeline?.runs_last_month || 0} />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="info-box">
                <Form.Item label="Created by">
                  <Input disabled value={pipeline?.created_by} />
                </Form.Item>
                <Form.Item label="Created at">
                  <Input 
                    disabled 
                    value={pipeline?.created_at && new Date(pipeline.created_at).toLocaleDateString()} 
                  />
                </Form.Item>
                <Form.Item label="Last Modified">
                  <Input 
                    disabled 
                    value={pipeline?.updated_at && new Date(pipeline.updated_at).toLocaleDateString()}
                  />
                </Form.Item>
                <Form.Item label="Last Run">
                  <Input 
                    disabled 
                    value={pipeline?.last_run ? new Date(pipeline.last_run).toLocaleDateString() : "Never"}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row justify="space-between">
            <Button className="delete-button" onClick={handleDelete}>
              Delete
            </Button>
            <div>
              <Button
                style={{ marginRight: 16 }}
                onClick={() => navigate(getUrl(ROUTES.PIPELINE_INFO, { workspaceId, pipelineId }))}
                type="secondary"
              >
                Cancel
              </Button>
              <Button className="save-button" htmlType="submit" type="primary">
                Save
              </Button>
            </div>
          </Row>
        </Form>
      </EditPageWrapper>
    </Layout>
  );
};

export default EditPipelinePage;
