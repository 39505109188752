import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, notification } from "antd";

import Logo from "../../../components/Logo/Logo.jsx";
import { fetchLogin } from "../../../connectors/auth";
import LoginImage from "./login-image.jpeg";

const LoginFormStyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  width: 100vw;
  background-color: #f0f2f5;

  .image {
    width: 50%;
    height: 90vh;
    align-self: flex-start;
    border-bottom-left-radius: 150px;
    background-image: url(${LoginImage});
    background-size: cover;
    background-position: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    padding: 10%;
    box-sizing: border-box;
  }

  .form {
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 30px;

    .login-form-container {
      padding: 40px;
      background: white;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      width: 100%;
      max-width: 400px;

      .login-form-title {
        margin-bottom: 0;
        color: #333;
        text-align: center;
      }

      .login-form-subtitle {
        margin-top: 10px;
        margin-bottom: 20px;
        color: gray;
        text-align: left;
        font-size: 16px;
      }

      .login-form-button {
        width: 100%;
        margin-top: 25px;
      }
    }
  }
`;

const LoginPage = () => {
  const [api, contextHolder] = notification.useNotification();

  const navigate = useNavigate();

  const handleFinish = async ({ username, password }) => {
    try {
      const response = await fetchLogin(username, password);
      api.success({ message: response.message });

      setTimeout(() => {
        navigate("/projects");
      }, 500);
    } catch (error) {
      api.error({ message: error.message });
      console.error("Login error:", error);
    }
  };

  return (
    <LoginFormStyledWrapper>
      {contextHolder}
      <div className="form">
        <div className="login-form-container">
          <h2 className="login-form-title">Login</h2>
          <p className="login-form-subtitle">
            Enter your username and password to sign in!
          </p>
          <Form layout="vertical" onFinish={handleFinish} requiredMark={false}>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input username!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder="Username"
                size="large"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Password"
                size="large"
              />
            </Form.Item>

            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                size="large"
                type="primary"
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="image">
        <Logo color="white" />
      </div>
    </LoginFormStyledWrapper>
  );
};

export default LoginPage;
