import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import NewProjectForm from './NewProjectForm'; // Import NewProjectForm

import loadConfig from '../../helpers/loadConfig';


const NewProjectPage = () => {
  const [configurations, setConfigurations] = useState(null);

  useEffect(() => {
    const fetchConfigurations = async () => {
      const configData = await loadConfig('/formConfig.json');
      setConfigurations(configData);
    };

    fetchConfigurations();
  }, []);

  if (!configurations) {
    return <div>Loading...</div>;
  }

  return (
    <Layout title="Create New Project">
      <NewProjectForm configurations={configurations} />
    </Layout>
  );
};

export default NewProjectPage;