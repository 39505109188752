import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Card } from "antd";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const getChartData = (data) => ({
  labels: data.map(({ name }) => name),
  datasets: [
    {
      label: "Label count",
      data: data.map(({ value }) => value),
      backgroundColor: [
        "rgba(255, 99, 132, 0.7)",
        "rgba(54, 162, 235, 0.7)",
        "rgba(255, 206, 86, 0.7)",
        "rgba(75, 192, 192, 0.7)",
        "rgba(153, 102, 255, 0.7)",
        "rgba(255, 159, 64, 0.7)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
});

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
  },
};

const LabelsChart = ({ data }) => {
  // TODO: get correct data from API and remove this mapping
  const tempDataMap = data.map((item) => ({
    name: Object.keys(item)[0],
    value: item[Object.keys(item)[0]],
  }));

  return (
    <Card>
      <Card.Meta title="Labels" className="card-title" />
      <Pie data={getChartData(tempDataMap)} options={options} />
    </Card>
  );
};

export default LabelsChart;
