import React from 'react';
import styled from 'styled-components';

const TitleContainer = styled.div`
  margin-bottom: 20px;
`;

const TitleText = styled.h2`
  font-size: 24px;
  color: #333;
  margin: 0;
`;

const TitleField = ({ title }) => (
  <TitleContainer>
    <TitleText>{title}</TitleText>
  </TitleContainer>
);

export default TitleField;