import React from 'react';
import styled from 'styled-components';

const SubformContainer = styled.div`
  margin-left: 20px;  // Indentation

  h4 {
    display: none;  // Hide the title
  }
`;

const Subform = ({ children }) => (
  <SubformContainer>
    {children}
  </SubformContainer>
);

export default Subform;