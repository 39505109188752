import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";

import { getUrl } from "../../helpers/navigation";
import { ROUTES } from "../../constants/routes";

const ProjectCardStyledWrapper = styled.div`
  width: 350px;
  height: 310px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > a {
    text-decoration: none !important;
  }

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  .project-card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .project-card-info-container {
    padding: 20px 10px;
    text-align: center;

    .project-card-title {
      margin: 0 0 15px;
      font-size: 20px;
      color: #333;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .project-card-subtitle {
      margin: 0;
      color: #666;
    }
  }
`;

const ProjectCard = ({ project: { id, title, name, created_at, image } }) => (
  <ProjectCardStyledWrapper>
    <Link to={getUrl(ROUTES.PROJECT_DASHBOARD, { id })}>
      <img alt={title} className="project-card-image" src={image} />
      <div className="project-card-info-container">
        <h3 className="project-card-title" title={name}>
          {name}
        </h3>
        <p
          className="project-card-subtitle"
          title={dayjs(created_at).format("DD of MMMM YYYY, HH:mm:ss")}
        >
          Created {dayjs(created_at).format("DD of MMMM YYYY")}
        </p>
      </div>
    </Link>
  </ProjectCardStyledWrapper>
);

export default ProjectCard;
