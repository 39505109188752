import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import DashboardPage from "./pages/ProjectDashboard/DashboardPage";
import DataPage from "./pages/ProjectData/DataPage";
import ProjectsList from "./pages/ProjectsList/ProjectsList";
import LoginPage from "./pages/Auth/LoginPage/LoginPage";
import ModelsPage from "./pages/ProjectModels/ModelsPage";
import NewProjectPage from "./pages/newProject/NewProjectPage";
import { ROUTES } from "./constants/routes";
import withAuth from "./HOCs/withAuth";

const {
  HOME,
  LOGIN,
  NEW_PROJECT,
  PROJECTS,
  PROJECT_DASHBOARD,
  PROJECT_DATA,
  PROJECT_MODELS,
} = ROUTES;

const PrivateProjectsPage = withAuth(ProjectsList);
const PrivateDashboardPage = withAuth(DashboardPage);
const PrivateDataPage = withAuth(DataPage);
const PrivateModelsPage = withAuth(ModelsPage);
const PrivateNewProjectPage = withAuth(NewProjectPage);

const App = () => (
  <div className="app">
    <Router>
      <Routes>
        <Route path={PROJECTS} element={<PrivateProjectsPage />} />
        <Route path={NEW_PROJECT} element={<PrivateNewProjectPage />} />
        <Route path={PROJECT_DATA} element={<PrivateDataPage />} />
        <Route path={PROJECT_MODELS} element={<PrivateModelsPage />} />
        <Route path={PROJECT_DASHBOARD} element={<PrivateDashboardPage />} />
        <Route path={LOGIN} element={<LoginPage />} />
        <Route path={HOME} element={<LoginPage />} />
      </Routes>
    </Router>
  </div>
);

export default App;
