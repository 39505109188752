import {
  DASHBOARD_DATA_FETCH_URL,
  PREDICTION_AVAILABLE_CHECK_FETCH_URL,
  PROJECTS_LIST_FETCH_URL,
  PROJECT_INFO_BY_ID_FETCH_URL,
  PROJECT_MODEL_FETCH_URL,
  TRIGGER_EVAL_MODEL_FETCH_URL,
  TRIGGER_GET_PREDICTION_FETCH_URL,
  TRIGGER_PROJECT_DATA_UPLOAD_FETCH_URL,
  TRIGGER_TRAIN_MODEL_FETCH_URL,
} from "../constants/endpoints";
import { getUrl } from "../helpers/navigation";

export const fetchDashboardData = async (projectId) => {
  const token = localStorage.getItem("token");

  const fetchURL = getUrl(DASHBOARD_DATA_FETCH_URL, { id: projectId });

  const response = await fetch(fetchURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const parsedResponse = await response.json();

  return parsedResponse;
};

export const fetchProjectInfoById = async (projectId) => {
  const token = localStorage.getItem("token");

  const fetchURL = getUrl(PROJECT_INFO_BY_ID_FETCH_URL, { id: projectId });

  const response = await fetch(fetchURL, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const parsedResponse = await response.json();

  return parsedResponse;
};

export const fetchTriggerEvalModel = async (projectId) => {
  const token = localStorage.getItem("token");

  const fetchURL = getUrl(TRIGGER_EVAL_MODEL_FETCH_URL, { id: projectId });

  const response = await fetch(fetchURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({}),
  });
  const parsedResponse = await response.json();

  return parsedResponse;
};

export const fetchTriggerTrainModel = async (projectId) => {
  const token = localStorage.getItem("token");

  const fetchURL = getUrl(TRIGGER_TRAIN_MODEL_FETCH_URL, { id: projectId });

  const response = await fetch(fetchURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({}),
  });
  const parsedResponse = await response.json();

  return parsedResponse;
};

export const fetchTriggerGetPrediction = async (projectId) => {
  const token = localStorage.getItem("token");

  const fetchURL = getUrl(TRIGGER_GET_PREDICTION_FETCH_URL, { id: projectId });

  const response = await fetch(fetchURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({}),
  });
  const parsedResponse = await response.json();

  return parsedResponse;
};

export const fetchProjectDataUpload = async (projectId, fileList) => {
  const token = localStorage.getItem("token");

  const fetchURL = getUrl(TRIGGER_PROJECT_DATA_UPLOAD_FETCH_URL, {
    id: projectId,
  });

  const formData = new FormData();
  for (let i = 0; i < fileList.length; i += 1) {
    formData.append("files", fileList[i]);
  }

  const response = await fetch(fetchURL, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  const parsedResponse = await response.json();

  return parsedResponse;
};

export const fetchProjectsList = async () => {
  const token = localStorage.getItem("token");

  const fetchURL = getUrl(PROJECTS_LIST_FETCH_URL);

  const response = await fetch(fetchURL, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const parsedResponse = await response.json();

  return parsedResponse;
};

export const fetchPredictionAvailableCheck = async (projectId) => {
  const token = localStorage.getItem("token");

  const fetchURL = getUrl(PREDICTION_AVAILABLE_CHECK_FETCH_URL, {
    id: projectId,
  });

  const response = await fetch(fetchURL, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const parsedResponse = await response.json();

  return parsedResponse;
};

export const fetchProjectModelData = async (projectId, type) => {
  const token = localStorage.getItem("token");

  const fetchURL = getUrl(PROJECT_MODEL_FETCH_URL, { id: projectId });

  // TODO: check this params with Albert
  const params = {
    type,
    sort_by: "start_time",
    ascending: true,
    page: 0,
    results_per_page: 10,
  };

  const response = await fetch(fetchURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  });
  const parsedResponse = await response.json();

  return parsedResponse;
};
