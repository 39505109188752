import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Spin, Breadcrumb } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import Sidebar from "../Sidebar/Sidebar";

const LayoutStyledWrapper = styled.div`
  display: flex;
  width: 100%;

  .layout-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;

    .layout-header-title-row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .layout-header-title {
      font-size: 32px;
      color: #333;
      margin: 0;
    }

    .layout-header-title-actions {
      display: flex;
      gap: 20px;
    }

    .breadcrumb {
      margin-bottom: 10px;
      font-size: 14px;
      color: #666;

      .ant-breadcrumb-link {
        color: #007bff;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .layout-main-content {
    flex-grow: 1;
    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .layout-loading-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

// TODO: check Breadcrumb component
const Layout = ({ children, headerChildren, isLoading, title, breadcrumbItems = [], initialCollapsed = false }) => (
  <LayoutStyledWrapper>
    <Sidebar initialCollapsed={initialCollapsed} />
    <div className="layout-main-content">
      <div className="layout-header">
        <Breadcrumb className="breadcrumb">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <Link to={item.path}>{item.label}</Link>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className="layout-header-title-row">
          <h2 className="layout-header-title">{title}</h2>
          <span className="layout-header-title-actions">{headerChildren}</span>
        </div>
      </div>
      {isLoading ? (
        <div className="layout-loading-container">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      ) : (
        <div>{children}</div>
      )}
    </div>
  </LayoutStyledWrapper>
);

export default Layout;
