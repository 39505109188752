import {
  CHECK_TOKEN_FETCH_URL,
  LOGIN_FETCH_URL,
  LOGIN_LABEL_STUDIO_FETCH_URL,
} from "../constants/endpoints";

export const fetchCheckToken = async () => {
  const token = localStorage.getItem("token");

  const response = await fetch(CHECK_TOKEN_FETCH_URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.ok) {
    const parsedResponse = await response.json();

    return parsedResponse;
  }

  throw new Error(response.statusText);
};

export const fetchLogin = async (username, password) => {
  const response = await fetch(LOGIN_FETCH_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  });

  if (response.ok) {
    const parsedResponse = await response.json();
    localStorage.setItem("token", parsedResponse.token);

    return parsedResponse;
  }

  throw new Error("Wrong username or password");
};

export const fetchLoginLabelStudio = async () => {
  const token = localStorage.getItem("token");

  const response = await fetch(LOGIN_LABEL_STUDIO_FETCH_URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.ok) {
    const parsedResponse = await response.json();

    return parsedResponse;
  }

  throw new Error("Error loggin in to Label Studio");
};
