// TODO: check and refactor this file
import React, { useRef, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import styled from "styled-components";

import Layout from "../../components/Layout/Layout";
import { fetchKnowledgeBaseById } from "../../connectors/api";
import { fetchLoginRagFlow } from "../../connectors/auth";
import { RAGFLOW_HOST_URL } from "../../constants/endpoints";
import { ROUTES } from "../../constants/routes";
import { getUrl } from "../../helpers/navigation";
import useWorkspaceContext from "../../hooks/useWorkspaceContext";

const MAX_NUMBER_OF_RETRIES = 5;

const ConfigIframeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 50px);
  top: -80px;
`;

const ConfigIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const ParentContainer = styled.div`
  overflow: hidden;
`;

const FailedLoadingWrapper = styled.div`
  max-width: 600px;
  margin: auto;
  text-align: center;
  padding: 40px 20px;

  .failed-loading-title {
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
  }

  .failed-loading-subtitle {
    line-height: 1.6;
    color: #666;
    font-size: 16px;
  }
`;

const KnowledgeBasePage = () => {
  const iframeRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [knowledgeBase, setKnowledgeBase] = useState(null);
  const [error, setError] = useState(null);
  const [ragflowKnowledgeBaseId, setRagflowKnowledgeBaseId] = useState(null);
  const [authToken, setAuthToken] = useState(null);

  const { workspaceId, knowledgeBaseId } = useParams();
  const location = useLocation();

  const { workspace } = useWorkspaceContext(workspaceId);

  const page = new URLSearchParams(location.search).get("page") || "dataset";

  const loginRagFlow = async () => {
    let count = 0;
    while (count < MAX_NUMBER_OF_RETRIES) {
      try {
        count += 1;
        const response = await fetchLoginRagFlow();
        setAuthToken(response.authorization_key);
        return true;
      } catch (error) {
        console.error("Retrying login to RagFlow", count);
      }
    }
    console.error("Failed to log in to RagFlow after multiple attempts");
    return false;
  };

  useEffect(() => {
    const fetchKnowledgeBaseInfo = async () => {
      try {
        const response = await fetchKnowledgeBaseById(workspaceId, knowledgeBaseId);
        setKnowledgeBase(response);
        setIsLoading(false);
        setRagflowKnowledgeBaseId(response.id);

        return true;
      } catch (error) {
        console.error("Failed to fetch Knowledge base info:", error);
        setError(error);
        setIsLoading(false);
        return false;
      }
    };

    (async () => {
      const success = await fetchKnowledgeBaseInfo();
      if (!success) return;
      await loginRagFlow();
      setIsLoading(false);
    })();

    return () => {};
  }, [workspaceId, knowledgeBaseId]);

  if (error) {
    return (
      <Layout title="Knowledge Base Configuration" isLoading={isLoading}>
        <FailedLoadingWrapper>
          <h2 className="failed-loading-title">Failed to load RagFlow configuration</h2>
          <p className="failed-loading-subtitle">
            Please try to reload this page or contact us to resolve your problem as soon as possible! Sorry for
            inconveniences and have a great day!
          </p>
        </FailedLoadingWrapper>
      </Layout>
    );
  }

  return (
    <Layout
      title={`Knowledge Base ${page.charAt(0).toUpperCase() + page.slice(1)}`}
      breadcrumbItems={[
        { label: "Workspaces", path: ROUTES.WORKSPACES },
        { label: workspace?.name, path: getUrl(ROUTES.WORKSPACE_INFO, { workspaceId }) },
        { label: "Knowledge Bases", path: getUrl(ROUTES.KNOWLEDGE_BASE, { workspaceId }) },
        {
          label: knowledgeBase?.name,
          path: `${getUrl(ROUTES.KNOWLEDGE_BASE_BY_ID, { workspaceId, knowledgeBaseId })}?page=dataset`,
        },
        {
          label: page.charAt(0).toUpperCase() + page.slice(1),
          path: `${getUrl(ROUTES.KNOWLEDGE_BASE_BY_ID, { workspaceId, knowledgeBaseId })}?page=${page}`,
        },
      ]}
      isLoading={isLoading}
    >
      <ParentContainer>
        <ConfigIframeWrapper>
          <ConfigIframe
            ref={iframeRef}
            src={`${RAGFLOW_HOST_URL}/knowledge/${page}?id=${ragflowKnowledgeBaseId}&auth=${encodeURIComponent(
              authToken
            )}`}
            title="Knowledge base configuration"
          />
        </ConfigIframeWrapper>
      </ParentContainer>
    </Layout>
  );
};

export default KnowledgeBasePage;
