import React from 'react';
import styled from 'styled-components';

const FormGroup = styled.div`
  padding-top: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;  // Make the label bold
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FormInput = ({ label, type, value, onChange, id, required }) => (
  <FormGroup>
    <Label htmlFor={id}>{label}</Label>
    <Input
      type={type}
      id={id}
      value={value}
      onChange={onChange}
      required={required}
    />
  </FormGroup>
);

export default FormInput;