import styled from "styled-components";

const SidebarStyledWrapper = styled.div`
  width: 250px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    width: 100px;
    margin-bottom: 7px;
    margin-right: 5px;
  }

  h2.sidebar-title {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;

    span.sidebar-subtitle {
      font-weight: normal;
    }
  }

  nav.sidebar-navigation {
    width: 100%;

    ul.sidebar-navigation-list {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li.sidebar-navigation-item {
        ${({ $isHomePath }) => (!$isHomePath ? "margin-left: 20px;" : "")}
      }
    }
  }
`;

export default SidebarStyledWrapper;
