import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { notification } from "antd";
import { FaPlus } from "react-icons/fa";

import Button from "../../components/Button/Button";
import Layout from "../../components/Layout/Layout";
import KnowledgeBaseCard from "../../components/KnowledgeBaseCard/KnowledgeBaseCard";
import { fetchKnowledgeBaseList } from "../../connectors/api";
import { ROUTES } from "../../constants/routes";
import { getUrl } from "../../helpers/navigation";
import useWorkspaceContext from "../../hooks/useWorkspaceContext";
import KnowledgeBaseCreateModal from "./KnowledgeBaseCreateModal";

const KnowledgeBaseGrid = styled.div`
  display: flex;
  gap: 20px;
  justify-content: left;
  flex-wrap: wrap;
`;

const CreateFirstKnowledgeBaseContainer = styled.div`
  background: #f8f8f8;
  border-radius: 12px;
  border: 1px dashed #e8e8e8;
  color: #8c8c8c;
  cursor: pointer;
  font-size: 18px;
  margin: 32px 0;
  padding: 24px;
  text-align: center;
`;

const KnowledgeBasePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [knowledgeBases, setKnowledgeBases] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { workspaceId } = useParams();

  const { workspace } = useWorkspaceContext(workspaceId);

  const loadKnowledgeBases = async () => {
    try {
      const response = await fetchKnowledgeBaseList(workspaceId);
      setKnowledgeBases(response);
      console.log(response);
    } catch (error) {
      notification.error({
        message: "Failed to load knowledge bases!",
        description: "Please reload the page, or if the problem persists, contact the Somia team for assistance.",
      });
      console.error("Failed to fetch list of knowledge bases", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadKnowledgeBases();
  }, []);

  const handleSubmitSuccess = () => {
    loadKnowledgeBases();
    setIsModalVisible(false);
  };

  return (
    <Layout
      title="Knowledge Bases"
      isLoading={isLoading}
      breadcrumbItems={[
        { label: "Workspaces", path: ROUTES.WORKSPACES },
        { label: workspace?.name, path: getUrl(ROUTES.WORKSPACE_INFO, { workspaceId }) },
        { label: "Knowledge Bases", path: getUrl(ROUTES.KNOWLEDGE_BASE, { workspaceId }) },
      ]}
      headerChildren={
        knowledgeBases.length > 0 && (
          <Button size="large" type="primary" onClick={() => setIsModalVisible(true)}>
            <FaPlus />
            Create Knowledge Base
          </Button>
        )
      }
    >
      {knowledgeBases.length ? (
        <KnowledgeBaseGrid>
          {knowledgeBases.map(({ id, name, created_at }) => (
            <KnowledgeBaseCard
              id={id}
              key={id}
              created_at={new Date(created_at).toLocaleDateString()}
              title={name}
            />
          ))}
        </KnowledgeBaseGrid>
      ) : (
        <CreateFirstKnowledgeBaseContainer onClick={() => setIsModalVisible(true)}>
          <p>You do not have any knowledge bases yet! Click here to create a new knowledge base now!</p>
        </CreateFirstKnowledgeBaseContainer>
      )}

      <KnowledgeBaseCreateModal
        onCancel={() => setIsModalVisible(false)}
        onSubmitSuccess={handleSubmitSuccess}
        visible={isModalVisible}
        workspaceId={workspaceId}
      />
    </Layout>
  );
};

export default KnowledgeBasePage;
