import React from "react";
import { useNavigate } from "react-router-dom";

import KnowledgeBaseCard from "../../components/KnowledgeBaseCard/KnowledgeBaseCard";
import { getUrl } from "../../helpers/navigation";
import { ROUTES } from "../../constants/routes";

const WorkspaceInfoKnowledgeBases = ({ knowledgeBases, workspaceId }) => {
  const navigate = useNavigate();

  const handleNewKnowledgeBase = () => {
    navigate(getUrl(ROUTES.KNOWLEDGE_BASE, { workspaceId }));
  };

  return (
    <div>
      <h2 className="section-title">Recent Knowledge Bases</h2>
      {knowledgeBases?.length > 0 ? (
        <div className="items-grid">
          {knowledgeBases.map(({ id, name, created_at }) => (
            <KnowledgeBaseCard
              id={id}
              key={id}
              created_at={new Date(created_at).toLocaleDateString()}
              title={name}
            />
          ))}
        </div>
      ) : (
        <p className="no-items-text" onClick={handleNewKnowledgeBase}>
          No knowledge bases yet. Click here to create one and enhance your workspace!
        </p>
      )}
    </div>
  );
};

export default WorkspaceInfoKnowledgeBases;
