export const getUrl = (urlRoute, pathParams, queryParams) => {
  let url = urlRoute;

  if (typeof pathParams === "object") {
    Object.entries(pathParams).forEach(([key, value]) => {
      url = url.replace(`:${key}`, value);
    });
  }

  if (typeof queryParams === "object") {
    url = `${url}?${new URLSearchParams(queryParams)}`;
  }

  return url.length > 0 ? url : undefined;
};
