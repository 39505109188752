import styled from "styled-components";
import {
  AimOutlined,
  BarChartOutlined,
  DeploymentUnitOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import { Card, Col, Progress, Row } from "antd";

import ModelsTable from "../../components/ModelsTable/ModelsTable";
import ImagesTable from "../../components/ImagesTable/ImagesTable";
import Infobox from "../../components/Infobox/Infobox";
import LabelsChart from "../../components/LabelsChart/LabelsChart";

const DashboardStyledWrapper = styled.div`
  .dashboard-row {
    margin-bottom: 25px;
  }

  .ant-card {
    height: 100%;

    .card-title {
      margin-bottom: 15px;
    }
  }

  .progress-chart-container {
    display: flex;
    justify-content: center;
    width: 100%;

    .ant-progress,
    .ant-progress-inner {
      width: 100% !important;
      height: 100% !important;
    }
  }
`;

const DashboardContent = ({ dashboardData, imagesData, modelsData }) => {
  if (dashboardData == null) {
    return <div>FAILED TO LOAD...</div>; // TODO: make it nice
  }

  return (
    <DashboardStyledWrapper>
      <Row gutter={[16]} className="dashboard-row">
        <Col xs={6}>
          <Infobox
            icon={<FileImageOutlined />}
            title="Images"
            value={dashboardData.imagesCount}
            type="raw"
          />
        </Col>
        <Col xs={6}>
          <Infobox
            icon={<BarChartOutlined />}
            title="Data Quality"
            value={dashboardData.dataQuality.value}
            description={dashboardData.dataQuality.description}
            type="points"
          />
        </Col>
        <Col xs={6}>
          <Infobox
            icon={<DeploymentUnitOutlined />}
            title="Model Quality"
            value={dashboardData.modelQuality}
            type="points"
          />
        </Col>
        <Col xs={6}>
          <Infobox
            icon={<AimOutlined />}
            title="Accuracy"
            value={dashboardData.accuracy}
            type="percent"
          />
        </Col>
      </Row>
      <Row gutter={16} className="dashboard-row">
        <Col xs={12}>
          <Card>
            <Card.Meta title="Images" className="card-title" />
            <ImagesTable data={imagesData} />
          </Card>
        </Col>
        <Col xs={6}>
          <Card>
            <Card.Meta title="Annotated data" className="card-title" />
            <div className="progress-chart-container">
              <Progress
                type="circle"
                percent={Math.round(dashboardData.annotatedData * 1000) / 10}
                size={250}
                status="active"
              />
            </div>
          </Card>
        </Col>
        <Col xs={6}>
          <LabelsChart data={dashboardData.labels} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={12}>
          <Card>
            <Card.Meta title="Train Models" className="card-title" />
            <ModelsTable data={modelsData.train.data} />
          </Card>
        </Col>
        <Col xs={12}>
          <Card>
            <Card.Meta title="Eval Models" className="card-title" />
            <ModelsTable data={modelsData.eval.data} />
          </Card>
        </Col>
      </Row>
    </DashboardStyledWrapper>
  );
};

export default DashboardContent;
