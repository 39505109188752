const loadConfig = async (path) => {
  try {
    const response = await fetch(path);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error fetching configurations from ${path}:`, error);
    return null;
  }
};

export default loadConfig;