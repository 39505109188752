import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import { fetchCheckToken } from "../connectors/auth";
import { ROUTES } from "../constants/routes";
import Layout from "../components/Layout/Layout";

const StyledLoading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const withAuth = (WrappedComponent) => {
  const AuthenticationCheck = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const validateToken = async () => {
      try {
        await fetchCheckToken();
        setIsLoading(false);
      } catch (error) {
        console.log("Error validationg token: ", error);
        navigate(ROUTES.LOGIN);
      }
    };

    useEffect(() => {
      validateToken();
    }, []);

    if (isLoading) {
      return (
        <Layout>
          <StyledLoading>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
            />
          </StyledLoading>
        </Layout>
      );
    }
    return <WrappedComponent {...props} />;
  };

  return AuthenticationCheck;
};

export default withAuth;
