import { useState } from "react";

import { WorkspaceContext } from "./WorkspaceContext";

const WorkspaceProvider = ({ children }) => {
  const [workspace, setWorkspace] = useState(null);

  return <WorkspaceContext.Provider value={{ workspace, setWorkspace }}>{children}</WorkspaceContext.Provider>;
};

export default WorkspaceProvider;
