import { refreshAccessToken } from "../connectors/auth";

export const fetchWithTokenRefresh = async (url, options = {}) => {
  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refresh_token");

  if (!token || !refreshToken) {
    throw new Error("Failed to fetch with token refresh - No token or refresh token found");
  }

  options.headers = {
    ...options.headers,
    Authorization: `Bearer ${token}`,
  };

  try {
    let response = await fetch(url, options);

    if (response.status === 401) {
      // Attempt to refresh the access token
      const newAccessToken = await refreshAccessToken();
      options.headers.Authorization = `Bearer ${newAccessToken}`;
      response = await fetch(url, options);
    }

    return response;
  } catch (error) {
    throw new Error("Failed to fetch with token refresh");
  }
};