import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import DashboardContent from "./DashboardContent";
import Layout from "../../components/Layout/Layout";
import {
  fetchDashboardData,
  fetchProjectModelData,
} from "../../connectors/api";

const DashboardPage = () => {
  const [projectName, setProjectName] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [modelsData, setModelsData] = useState({
    eval: { data: null, isLoading: true },
    train: { data: null, isLoading: true },
  });
  const [imagesData, setImagesData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const data = await fetchDashboardData(id);
        setIsLoading(false);

        console.log("DashboardPage data", data);

        setProjectName(data.name);
        setDashboardData(data.dashboard);
        setImagesData(data.data);
      } catch (error) {
        console.log(
          "Fetch of the dashboard data has failed with the error: ",
          error
        );
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchModelData = async (type) => {
      try {
        const response = await fetchProjectModelData(id, type);
        console.log("fetchModelData", { type, response });
        setModelsData((prevState) => ({
          ...prevState,
          [type]: { data: response, isLoading: false },
        }));
      } catch (error) {
        console.error(`Failed to fetch model data for ${type}:`, error);
        setModelsData((prevState) => ({
          ...prevState,
          [type]: { data: null, isLoading: false },
        }));
      }
    };

    fetchModelData("eval");
    fetchModelData("train");
  }, [id]);

  const isPageLoading =
    isLoading || modelsData.eval.isLoading || modelsData.train.isLoading;

  return (
    <Layout title={projectName} isLoading={isPageLoading}>
      <DashboardContent
        dashboardData={dashboardData}
        imagesData={imagesData}
        modelsData={modelsData}
      />
    </Layout>
  );
};

export default DashboardPage;
