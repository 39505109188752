import React from 'react';
import FormInput from '../FormInput/FormInput';
import SelectInput from '../SelectInput/SelectInput';
import TitleField from '../FormTitleField/FormTitleField';

const GenericFormField = ({ fieldConfig, value, onChange }) => {
  const { type, label, id, required } = fieldConfig;
  switch (type) {
    case 'text':
      return <FormInput label={label} id={id} value={value} onChange={onChange} required={required} />;
    case 'select':
      return <SelectInput label={label} id={id} value={value?.option || (typeof value === 'string' ? value : fieldConfig.default)} onChange={onChange} options={fieldConfig.options} required={required} />;
    case 'title': 
      return <TitleField title={label} />;
    default:
      return <div>Unsupported field type</div>;
  }
};

export default GenericFormField;