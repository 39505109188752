// TODO: check and refactor this file
import React, { useState } from "react";
import { NavLink, useLocation, useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import cn from "classnames";
import {
  FaHome,
  FaUser,
  FaSignOutAlt,
  FaArrowAltCircleLeft,
  FaBorderAll,
  FaAtom,
  FaInfoCircle,
  FaCog,
  FaCogs,
  FaVial,
  FaTable,
  FaRobot,
  FaBrain,
  FaChevronRight,
  FaBars,
} from "react-icons/fa";

import SidebarStyledWrapper from "./SidebarStyledWrapper";
import { ROUTES } from "../../constants/routes";
import { getUrl } from "../../helpers/navigation";
import useWorkspaceContext from "../../hooks/useWorkspaceContext";

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin: 5px 0;
`;

const WorkspaceName = styled.div`
  font-size: 18px;
  font-weight: 900;
  color: black;
  margin: 10px 0 5px 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  letter-spacing: -0.5px;
  padding-top: 10px;
  padding-bottom: 5px;
`;

const CollapseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: #666;
  position: absolute;
  top: 5px;
  right: 10px;

  &:hover {
    color: #333;
  }
`;

const linkStyles = `
  text-decoration: none;
  color: #444;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 4px;
  position: relative;
  font-weight: 500;

  svg {
    margin-right: ${({ $isCollapsed }) => ($isCollapsed ? "0" : "8px")};
    color: #888;
    font-size: 16px;
  }

  span {
    padding-left: 8px;
  }

  &:hover {
    background-color: #f5f5f5;
  }
`;

const StyledNavLink = styled(NavLink)`
  ${linkStyles}

  &.active-link {
    color: #111;
    background-color: #ebf3ff;
    border-left: 4px solid #182eae;
    padding-left: 6px;

    svg {
      color: #182eae;
    }
  }
`;

const SubMenuToggle = styled.div`
  ${linkStyles}

  cursor: pointer;

  &.active {
    background-color: #ebf3ff;
    border-left: 4px solid #3b82f6;
    padding-left: 6px;
    color: #111;

    svg.icon {
      color: #3b82f6;
    }
  }

  svg.chevron {
    margin-left: auto;
    transition: transform 0.3s;
    color: #888;
    font-size: 12px;

    &.open {
      transform: rotate(90deg);
    }
  }
`;

const SubMenuList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  &.submenu-level-1 {
    margin-left: ${({ $isCollapsed }) => ($isCollapsed ? "0" : "20px")};
    border-left: ${({ $isCollapsed }) => ($isCollapsed ? "none" : "1px solid #eee")};
    padding-left: ${({ $isCollapsed }) => ($isCollapsed ? "0" : "10px")};
  }

  &.submenu-level-2 {
    margin-left: ${({ $isCollapsed }) => ($isCollapsed ? "0" : "20px")};
    border-left: ${({ $isCollapsed }) => ($isCollapsed ? "none" : "1px dashed #eee")};
    padding-left: ${({ $isCollapsed }) => ($isCollapsed ? "0" : "10px")};
    font-size: 13px;
    color: #555;
  }

  li {
    margin-bottom: 3px;
  }
`;

const SectionHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-top: 10px;
  color: #333;
`;

const homeSideMenu = [
  {
    icon: <FaHome />,
    path: ROUTES.WORKSPACES,
    label: "Workspaces",
  },
];

const profileMenuConfig = [
  {
    icon: <FaCog />,
    path: "/profile",
    label: "User Configuration",
  },
  {
    icon: <FaSignOutAlt />,
    path: ROUTES.LOGIN,
    label: "Log out",
    onClick: () => localStorage.removeItem("token"),
  },
];

const workspaceMenuConfig = [
  {
    icon: <FaBorderAll />,
    path: ROUTES.WORKSPACE_INFO,
    label: "Overview",
  },
];

const modelsMenuConfig = [
  {
    icon: <FaAtom />,
    path: ROUTES.UNDER_CONSTRUCTION,
    label: "All Projects",
  },
];

const pipelinesMenuConfig = [
  {
    icon: <FaInfoCircle />,
    path: ROUTES.PIPELINE_INFO,
    label: "Agent Info",
  },
  {
    icon: <FaCogs />,
    path: ROUTES.AGENT_CONFIGURATION,
    label: "Agent Configuration",
  },
  {
    icon: <FaVial />,
    path: ROUTES.AGENT_TESTING,
    label: "Agent Testing",
  },
];

const knowledgeBaseMenuConfig = [
  {
    icon: <FaTable />,
    path: `${ROUTES.KNOWLEDGE_BASE_BY_ID}?page=dataset`,
    label: "Dataset",
  },
  {
    icon: <FaCogs />,
    path: `${ROUTES.KNOWLEDGE_BASE_BY_ID}?page=configuration`,
    label: "Configuration",
  },
  {
    icon: <FaVial />,
    path: `${ROUTES.KNOWLEDGE_BASE_BY_ID}?page=testing`,
    label: "Testing",
  },
];

const automationMenuConfig = [
  {
    icon: <FaRobot />,
    path: ROUTES.PIPELINES,
    label: "All Agents",
    submenu: pipelinesMenuConfig,
  },
  {
    icon: <FaBrain />,
    path: ROUTES.KNOWLEDGE_BASE,
    label: "Knowledge Bases",
    submenu: knowledgeBaseMenuConfig,
  },
];

const isWorkspaceDetails = (pathname) => {
  const pathArray = pathname.split("/");
  return pathArray[1] === "workspaces" && !isNaN(pathArray[2]);
};

const Sidebar = ({ initialCollapsed = false }) => {
  const [isCollapsed, setIsCollapsed] = useState(initialCollapsed);
  const [profileOpen, setProfileOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const { workspace } = useWorkspaceContext(params.workspaceId);

  const isHomePath = !isWorkspaceDetails(location.pathname);
  const isPipelineSelected = params.pipelineId;
  const isKnowledgeBaseSelected = params.knowledgeBaseId;

  const menuConfig = isHomePath ? homeSideMenu : workspaceMenuConfig;

  const isActive = (path) => {
    const url = getUrl(path, params);
    const locationPathWithParams = `${location.pathname}${location.search}`;
    return locationPathWithParams === url;
  };

  const renderMenuItems = (menuConfig) =>
    menuConfig.map(({ icon, path, label, onClick }) => {
      const active = isActive(path);

      return (
        <li className="sidebar-navigation-item" key={path}>
          <StyledNavLink to={getUrl(path, params)} className={cn({ "active-link": active })} onClick={onClick}>
            {icon} <span>{!isCollapsed && label}</span>
          </StyledNavLink>
        </li>
      );
    });

  const renderSubMenuItems = (subMenuConfig, isOpen) =>
    isOpen && (
      <SubMenuList className="submenu-level-1" $isCollapsed={isCollapsed}>
        {subMenuConfig.map(({ icon, path, label }) => (
          <li className="sidebar-navigation-item submenu-item" key={path}>
            <StyledNavLink to={getUrl(path, params)} className={cn({ "active-link": isActive(path) })}>
              {icon} <span>{!isCollapsed && label}</span>
            </StyledNavLink>
          </li>
        ))}
      </SubMenuList>
    );

  const checkIsOpen = (menuName) => {
    if (menuName === "All Agents") {
      return isPipelineSelected;
    }
    if (menuName === "Knowledge Bases") {
      return isKnowledgeBaseSelected;
    }
    return false;
  };

  return (
    <SidebarStyledWrapper $isHomePath={isHomePath} $isCollapsed={isCollapsed}>
      <div className="sidebar-header">
        <div className="sidebar-header-wrapper">
          {!isCollapsed && (
            <div className="sidebar-logo" onClick={() => navigate(ROUTES.WORKSPACES)}>
              <span className="sidebar-company-name">SOMIA</span>
              <span className="sidebar-platform-name">PLATFORM</span>
            </div>
          )}
          <CollapseButton onClick={() => setIsCollapsed(!isCollapsed)}>
            <FaBars />
          </CollapseButton>
        </div>
      </div>
      <nav className="sidebar-navigation">
        <ul className="sidebar-navigation-list">
          <li className="sidebar-navigation-item">
            <SubMenuToggle
              className={cn({ active: location.pathname === "/profile" })}
              onClick={() => {
                setProfileOpen(!profileOpen);
                setIsCollapsed(false);
              }}
            >
              <FaUser /> <span>{!isCollapsed && "Profile"}</span>
              {!isCollapsed && <FaChevronRight className={cn("chevron", { open: profileOpen })} />}
            </SubMenuToggle>
            {profileOpen && (
              <SubMenuList className="submenu-level-1" $isCollapsed={isCollapsed}>
                {profileMenuConfig.map(({ icon, path, label, onClick }) => (
                  <li className="sidebar-navigation-item submenu-item" key={path}>
                    <StyledNavLink
                      to={path}
                      className={cn({ "active-link": location.pathname === path })}
                      onClick={onClick}
                    >
                      {icon} <span>{!isCollapsed && label}</span>
                    </StyledNavLink>
                  </li>
                ))}
              </SubMenuList>
            )}
          </li>
        </ul>
      </nav>
      <Divider />
      <nav className="sidebar-navigation">
        <ul className="sidebar-navigation-list">
          {!isHomePath && (
            <>
              <li>
                <StyledNavLink to={ROUTES.WORKSPACES}>
                  <FaArrowAltCircleLeft /> <span>{!isCollapsed && "Back to Workspaces"}</span>
                </StyledNavLink>
              </li>
              {!isCollapsed && <WorkspaceName>{workspace?.name}</WorkspaceName>}
              {renderMenuItems(menuConfig)}
              {!isCollapsed && <SectionHeader>AUTOMATION</SectionHeader>}
              {automationMenuConfig.map(({ icon, path, label, submenu }) => (
                <li className="sidebar-navigation-item" key={path}>
                  {checkIsOpen(label) ? (
                    <SubMenuToggle
                      onClick={() => (window.location.href = getUrl(path, params))}
                      className={cn({ "active-link": isActive(path) })}
                    >
                      {icon} <span>{!isCollapsed && label}</span>
                      {!isCollapsed && submenu && (
                        <FaChevronRight className={cn("chevron", { open: checkIsOpen(label) })} />
                      )}
                    </SubMenuToggle>
                  ) : (
                    <StyledNavLink to={getUrl(path, params)} className={cn({ "active-link": isActive(path) })}>
                      {icon} <span>{!isCollapsed && label}</span>
                    </StyledNavLink>
                  )}
                  {checkIsOpen(label) && renderSubMenuItems(submenu, checkIsOpen(label))}
                </li>
              ))}
              {!isCollapsed && <SectionHeader>MODELS</SectionHeader>}
              {renderMenuItems(modelsMenuConfig)}
            </>
          )}
        </ul>
      </nav>
    </SidebarStyledWrapper>
  );
};

export default Sidebar;
