import React from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import cn from "classnames";
import {
  FaHome,
  FaUser,
  FaSignOutAlt,
  FaArrowAltCircleLeft,
  FaBorderAll,
  FaAtom,
  FaDatabase,
} from "react-icons/fa";

import Logo from "../Logo/Logo";
import SidebarStyledWrapper from "./SidebarStyledWrapper";
import { ROUTES } from "../../constants/routes";
import { getUrl } from "../../helpers/navigation";

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin-bottom: 40px;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: #aaa;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 10px;

  &.active-link {
    font-weight: bold;
    color: black;

    svg {
      color: #00008b;
    }
  }

  &:hover {
    background-color: #f0f0f0;
  }

  svg {
    margin-right: 10px;
  }
`;

const homeSideMenu = [
  {
    icon: <FaHome />,
    path: ROUTES.PROJECTS,
    label: "Projects",
  },
  {
    icon: <FaUser />,
    path: "/profile", // TODO: implement and add to routes
    label: "Profile",
  },
  {
    icon: <FaSignOutAlt />,
    path: ROUTES.LOGIN,
    label: "Logout",
    onClick: () => localStorage.removeItem("token"), // TODO: logout also on bakcend, when Albert implements the API
  },
];

const projectMenuConfig = [
  {
    icon: <FaBorderAll />,
    path: ROUTES.PROJECT_DASHBOARD,
    label: "Dashboards",
  },
  {
    icon: <FaAtom />,
    path: ROUTES.PROJECT_MODELS,
    label: "Models ",
  },
  {
    icon: <FaDatabase />,
    path: ROUTES.PROJECT_DATA,
    label: "Data",
  },
];

// TODO: implement a better way to detect correct path
const isProjectDetails = (pathname) => {
  const pathArray = pathname.split("/");
  return pathArray[1] === "projects" && !isNaN(pathArray[2]);
};

const Sidebar = () => {
  const params = useParams();
  const location = useLocation();

  const isHomePath = !isProjectDetails(location.pathname);

  const menuConfig = isHomePath ? homeSideMenu : projectMenuConfig;

  const isActive = (path) => location.pathname === getUrl(path, params);

  return (
    <SidebarStyledWrapper $isHomePath={isHomePath}>
      <h2 className="sidebar-title">
        <Logo className="logo" />
        <span className="sidebar-subtitle">PLATFORM</span>
      </h2>
      <Divider />
      <nav className="sidebar-navigation">
        <ul className="sidebar-navigation-list">
          {!isHomePath && (
            <li>
              <StyledNavLink to={ROUTES.PROJECTS}>
                <FaArrowAltCircleLeft /> Back to Projects
              </StyledNavLink>
            </li>
          )}
          {menuConfig.map(({ icon, path, label, onClick }) => (
            <li className="sidebar-navigation-item" key={path}>
              <StyledNavLink
                to={getUrl(path, params)}
                className={cn({ "active-link": isActive(path) })}
                onClick={onClick}
              >
                {icon} {label}
              </StyledNavLink>
            </li>
          ))}
        </ul>
      </nav>
    </SidebarStyledWrapper>
  );
};

export default Sidebar;
