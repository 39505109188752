import React from 'react';
import styled from 'styled-components';

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  label {
    margin-bottom: 5px;
    font-weight: bold;  // Make the label bold
  }
`;

const Select = styled.select`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const SelectInput = ({ label, options, value, onChange, id, required }) => {
  return (
    <FormGroup>
      <label htmlFor={id}>{label}</label>
      <Select id={id} value={value} onChange={onChange} required={required}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>{option.label}</option>
        ))}
      </Select>
    </FormGroup>
  );
};

export default SelectInput;
