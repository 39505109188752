const Logo = ({ className, color = "black" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 444 115.27"
    fill={color}
    className={className}
  >
    <g data-name="Layer 2">
      <g data-name="Full Logo">
        <path
          className="cls-1"
          d="M68.88,68.06h0c-4.41-1.75-10.39-3-17.75-3.82l-16.45-2c-4.75-.6-8-1.48-9.81-2.61a4.54,4.54,0,0,1-2.22-4.1c0-2.4,1.34-4.24,4.09-5.61,3.1-1.55,7.4-2.34,12.76-2.34a34.11,34.11,0,0,1,10.09,1.34A15.77,15.77,0,0,1,56,52.48a8.79,8.79,0,0,1,2.6,5.09L58.79,59H80.05L80,57.22a23.24,23.24,0,0,0-5.86-14.38,35.17,35.17,0,0,0-14.2-9.38,56.64,56.64,0,0,0-20-3.29,61.52,61.52,0,0,0-20,3c-5.8,2-10.36,5-13.57,8.85A21.58,21.58,0,0,0,1.34,56.27c0,7.08,2.52,12.69,7.49,16.67s12.42,6.37,23,7.55l15.82,1.78c5.29.59,8.9,1.5,10.73,2.71a4.87,4.87,0,0,1,2.33,4.4c0,2.58-1.41,4.48-4.46,6-3.34,1.66-8,2.5-14,2.5a34.26,34.26,0,0,1-10.74-1.54,17.12,17.12,0,0,1-7-4.18,11,11,0,0,1-3.07-6.06l-.22-1.43H0l.08,1.78A25.36,25.36,0,0,0,6,101.72a35.15,35.15,0,0,0,14.74,10A59.38,59.38,0,0,0,42,115.27a58.8,58.8,0,0,0,20.72-3.35,32.52,32.52,0,0,0,14.07-9.69,23,23,0,0,0,5.14-14.84,21.54,21.54,0,0,0-3-11.82C76.84,72.42,73.48,69.89,68.88,68.06Z"
        />
        <path
          className="cls-1"
          d="M159,35.48c-7-3.52-15.39-5.31-24.79-5.31s-17.74,1.79-24.8,5.31A40.71,40.71,0,0,0,92.66,50.39a41.24,41.24,0,0,0-6,22.34,41.22,41.22,0,0,0,6,22.32A40.74,40.74,0,0,0,109.41,110c7.07,3.52,15.41,5.31,24.81,5.31S152,113.48,159,110a40.71,40.71,0,0,0,16.76-14.91,41.19,41.19,0,0,0,6-22.32,41.21,41.21,0,0,0-6-22.34A40.72,40.72,0,0,0,159,35.48Zm1.39,37.25a23.87,23.87,0,0,1-3.17,12.44,20.93,20.93,0,0,1-8.92,8,32.23,32.23,0,0,1-14.09,2.87,32.32,32.32,0,0,1-14-2.87,21,21,0,0,1-9-8A23.87,23.87,0,0,1,108,72.73a23.94,23.94,0,0,1,3.16-12.45,21,21,0,0,1,9-8,32.18,32.18,0,0,1,14-2.88,32.15,32.15,0,0,1,14.08,2.87,20.89,20.89,0,0,1,8.93,8A23.92,23.92,0,0,1,160.4,72.73Z"
        />
        <rect
          className="cls-1"
          x="317.05"
          y="31.74"
          width="21.19"
          height="81.97"
        />
        <path
          className="cls-1"
          d="M405.63,31.74h-25l-38.36,82H365l7.47-16.55h41.37l7.46,16.55H444Zm.15,47.57H380.5l12.64-28Z"
        />
        <path
          className="cls-1"
          d="M294.32,21.11q-5.74,0-8.46-3a10.93,10.93,0,0,1-2.73-7.63,10.66,10.66,0,0,1,2.73-7.55Q288.58,0,294.32,0c3.82,0,6.61,1,8.39,2.93a10.88,10.88,0,0,1,2.65,7.55,11.16,11.16,0,0,1-2.65,7.63C300.93,20.11,298.14,21.11,294.32,21.11Z"
        />
        <path
          className="cls-1"
          d="M200.93,21.11q-5.73,0-8.46-3a11,11,0,0,1-2.73-7.63,10.71,10.71,0,0,1,2.73-7.55Q195.2,0,200.93,0t8.39,2.93A10.84,10.84,0,0,1,212,10.48a11.12,11.12,0,0,1-2.66,7.63Q206.67,21.11,200.93,21.11Z"
        />
        <path
          className="cls-1"
          d="M304.44,31.72v82H284.07V72.2a60.3,60.3,0,0,1-6.92,4c-8.37,4.23-18.34,6.34-29.58,6.34s-21.22-2.11-29.67-6.34a45.3,45.3,0,0,1-6.84-4v41.49H190.69v-82h20.37a33.1,33.1,0,0,0,4.39,17.41A29.27,29.27,0,0,0,228,60.28c5.49,2.71,12.08,4.06,19.61,4.06s14.11-1.35,19.6-4.06A29.06,29.06,0,0,0,279.6,49.13a33.23,33.23,0,0,0,4.47-17.41Z"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
